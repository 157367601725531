.bannerContainer {
    padding: 10em 0;
    // padding-top: 5em;
    background: url('../../img/background.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    /*  justify-content: flex-end; */
    align-items: center;
    overflow: hidden;


    .actionsDetail {
        /*  margin-left: 20vw; */
        flex: 1;

        .banner-title>h1 {
            font-size: calc(1em + 2vw);
            font-weight: 600;
        }

        .banner-title>h1>i {
            text-decoration: underline;
            text-decoration-color: #00ee00;
        }

        .banner-description {
            max-width: 30em;
        }

        .btn {
            border-radius: 1rem;
            font-weight: 600;
        }

        .button>a {
            text-decoration: none;
        }
    }

    .avatarContainer {
        .avatar {
            width: 20vw;
        }
    }

}


.btn-success {
    background-color: #00ee00 !important;
    color: #000 !important;
    border: none !important;
}

.icon {
    color: #000;
}

@media screen and (max-width: 800px) {
    .bannerContainer {
        padding: 8em 0;
        //padding-bottom: 2rem;
        flex-direction: column;

        .actionsDetail {
            margin-left: 0;

            /*   .button {
                position: absolute;
                flex-direction: column;
                right: 2rem;
                margin-top: 100px !important;

                .regulated-button {
                    width: 10rem;
                }
            } */
        }

        /*  .avatarContainer {
            margin-top: 2rem;
            margin-right: auto;

            .avatar {
                width: calc(100px + 20vw);
            }
        }

        .finishEffect {
            width: 100vw;
            height: 1rem;
            background: linear-gradient(to right, #00d700f2, transparent);
        }

        .finishEffect2 {
            width: 100vw;
            height: 0.8rem;
            background: linear-gradient(to right, transparent, #00d700f2);
        } */
    }
}

@media (min-width: 500px) and (max-width: 800px) {
    .bannerContainer {
        .actionsDetail {
            .button {
                width: 40vw;
            }
        }
    }

}