/* Testes */

/* Fontes Montserrat */
@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat"),
    url("../../assets/Fonts/Montserrat/Montserrat-Regular.ttf")
      format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat"),
    url("../../assets/Fonts/Montserrat/Montserrat-Medium.ttf")
      format("truetype");
}
@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat"),
    url("../../assets/Fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
}
