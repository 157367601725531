$Gradiente_Fundo_body: linear-gradient(90deg, #137a53 0%, #05fe9f 100%);
$Cor_Sombra: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12),
  0px 8px 10px rgba(0, 0, 0, 0.2);
$Cor_Fundo_Box_Left: #fff;
$Cor_Borda_Campos: #c6c8c9;
$Cor_Botao_Entrar: #009c60;
$Cor_Hover_Botao_Entrar: #009930;

* {
  font-family: Sora !important;
}


.ModalWithdraw {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(black, 0.6);

  .WithdrawForm {
    background-color: white;
    min-width: 450px;
    padding: 18px 16px;
    border-radius: 10px;
    position: relative;

    #StepInd {
      font-weight: 700;
      color: #A1A4AC;
      text-align: center;
    }

    .groupText {
      display: flex;
      flex-direction: column;
      width: 400px;
      margin: 0 auto;
      align-items: center;
      text-align: center;

      h3 {
        font-weight: 700;
        color: #409900;
      }

      p {
        width: 90%;
        font-weight: 700;
      }
    }

    .closeButton {
      position: absolute;
      color: #212529;
      right: 1rem;
      top: 12px;
      background-color: white;
      opacity: .7;
      outline: none;
      padding: 10px;
      width: 30px;
      height: 30px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      transition: .3s all;

      &:hover {
        transform: scale(1.1);
        opacity: .85;
      }

      img {
        width: 16px;
      }
    }

    .return {
      position: absolute;
      left: 1rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      transition: .3s all;
      height: 30px;
      top: 12px;
      opacity: .85;

      &:hover {
        transform: scale(1.1);
        opacity: 1;
      }

      img {
        width: 30px;
      }
    }

    .error-message {
      margin-left: 0.5rem;
      font-size: 0.8rem;
      color: red;
      font-weight: 700;
      text-align: left;
    }

    label {
      margin-bottom: 8px;
    }
  }

  .form-group {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 16px;

    select,
    input {
      border: .8px solid #000;
      outline: none;
      font-weight: 700;
      transition: .3s all;

      &:focus {
        box-shadow: 0 0 0 3px #0ACC0A;
        border: none;
      }
    }

    .progress {
      background: none;
      margin-left: 8px;
    }
  }

  .btn-next {
    width: 100%;
    font-weight: 700;
    font-family: Sora;
    padding: 3px 5px;
    background: #0ACC0A;
    color: #000;
    transition: .7s all;
    border: 4px solid #0ACC0A;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    &:hover {
      background: transparent;
      color: #0ACC0A;

    }

    &:disabled {
      opacity: 0.7;
      cursor: wait;
    }
  }

  .btn-next-value {
    &:disabled {
      cursor: initial;
    }
  }

  .steps {
    margin-top: 6px;
    display: block;
    text-align: center;
    color: #222;
    font-size: 0.75rem;
  }

  .balanceInfo {
    width: 80%;
    margin: 0 auto;
    padding: 25px 15px;
    margin-bottom: 32px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: transparent;
    border: 1px solid #000;
    color: #0ACC0A;
    text-align: center;

    p {
      font-size: 1.4em;
      font-weight: 700;
      margin: initial;
    }

    h1 {
      margin-top: 4px;
      font-weight: 800;
    }
  }
}

@media (max-width: 480px) {
  .ModalWithdraw {
    .WithdrawForm {
      background-color: white;
      min-width: 340px;
      margin: 0 auto;
      max-width: 340px;
      padding: 18px 16px;
      border-radius: 10px;
      position: relative;

      .groupText {
        width: 320px;

        p {
          width: 100%;
        }
      }

      .balanceInfo {
        width: 100%;
      }

      .FormWrapper {
        .form-group {
          width: 100%;

        }
      }
    }
  }
}

@media (max-width: 360px) {
  .ModalWithdraw {
    .WithdrawForm {
      min-width: 300px;
      max-width: 300px;

      .groupText {
        width: 280px;
      }
    }
  }
}