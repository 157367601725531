.StoreCategoriesListContainer {
    overflow-y: auto;
}
.PurchaseScreen {
    background-color: #e0dcdc;
    height: 100vh;

    .main-content {
        background-color: #e0dcdc;
        width: 100%;
        margin: 30px auto 0 auto;

        padding: 25px;
        display: flex;
        flex-direction: column;
        height: 100vh;
        &.no-margin {
            margin-top: 40px;
        }
    }
    .rodapeCliente_Purchases {
        position: fixed;
        padding-top: auto;
        bottom: 0;
        z-index: 2000;
        width: 100%;
    }

    .mock-table {
        margin-top: 0;
    }
}

.NewCategoryFormWrapper {
    display: flex;
    width: 93vw;
    background-color: white;
    padding: 40px;
    margin: 50px auto 20px auto;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
    max-width: 1500px;
    gap: 40px;
    overflow-x: auto;
    flex-direction: column;
    border-radius: 8px;
    padding-bottom: 60px;
    transition: 1.4s all;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow-x: auto;

    h2 {
        font-family: Sora;
        margin: 0;
        font-size: 25px;
        color: #a6a6a6;
        font-weight: bold;
    }

    .input-wrapper {
        margin-bottom: 1rem;
    }

    .description-category-textarea {
        display: block;
        width: 100%;
    }

    .new-category-input,
    .description-category-textarea {
        padding: 8px;
        padding-left: 12px;
        font-size: 16px;
        font-family: Sora;
        outline: none;
        width: 100%;
        border-radius: 7.5px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border: 1.5px solid #000;

        &:focus {
            border: 2px solid #00ee00;
        }
    }

    .btn-new-category {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        max-width: 300px;
        font-weight: bold;
        font-family: Sora;
        color: white;
        font-size: 18px;
        border-radius: 10px;
        background-color: #0acc0a;
        border: none;
        transition: 0.5s all;
        padding: 1rem 1.2rem;

        &:disabled {
            background-color: rgba($color: #0acc0a, $alpha: 0.5);
        }
    }
}

@media screen and (max-width: 772px) {
    .NewCategoryFormWrapper {
        .input-wrapper {
            flex-direction: column;
        }

        .btn-new-category {
            width: 100%;
            max-width: initial;
            padding: 0.5rem;
        }
    }
}

@media screen and (min-width: 742px) {
    .PurchaseScreen {
        .main-content {
            margin-top: 64px;
        }
    }
}
