.StoreGrid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 2rem;
  justify-items: center;
  padding: 1rem 0;
}

// @media screen and (min-width: 768px) {
//   .StoreGrid {
//     padding: 1rem;
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// @media screen and (min-width: 1024px) {
//   .StoreGrid {
//     padding: 1rem;
//     grid-template-columns: repeat(3, 1fr);
//   }
// }