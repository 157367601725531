.StoreModal {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  .StoreInfo {
    padding: 20px 40px;
    background-color: #fff;
    max-width: 580px !important;
    border-radius: 25px;
    width: 440px;
    position: relative;
    margin: auto;

    img {
      width: 90%;
      margin: 0 auto;
      border-radius: 15px;
    }

    .StoreInfo__segments {
      list-style: none;
      margin: 0;
      padding: 0;

      color: #007bff;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin-right: 2px;
      }
    }

    .StoreInfo__address {
      margin-top: 1rem;

      label {
        width: 100%;
        font-weight: 700;
        font-size: 1em;
        margin-bottom: 5px;
        text-align: left;
      }

      .address_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0.9px solid #000;
        padding: 0 0.5rem 0;
        border-radius: 10px;
        padding: 10px 5px;

        p {
          margin-left: 20px !important;
          font-size: .9em;
          margin: 0;
          font-weight: 600;
          color: #A8A8A8;
          text-align: left;
        }

        .see-map-link {
          background-color: #fff;
          padding: 0 1rem;

          a {
            font-weight: 600;
          }

          img {
            height: 25px;
            width: 20px;
            transition: .7s all;

            &:hover {
              transform: scale(1.15);
            }
          }
        }
      }
    }

    &__name,
    &__contact,
    &__segments,
    &__address {
      padding: 0 1rem;
    }

    .StoreInfo__name {
      margin-top: 10px;
      font-weight: 700;
      font-size: 1.5rem;
      color: #409900;
      margin-bottom: 10px;
    }

    .StoreInfo__contact {
      margin-top: 5px;
      padding: 0.5rem 1rem 1rem;
      text-align: left;
      display: flex;
      flex-direction: column;

      .contactContainer {
        margin-bottom: 19px;
        display: flex;
        flex-direction: column;

        label {
          width: 100%;
          font-weight: 700;
          font-size: 1em;
          margin-bottom: 5px;
          text-align: left;
        }
      }
    }

    .phone,
    .btn-whatsapp {
      display: inline-block;
    }

    .phone {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 0.9px solid #000;
      padding: 0 0.5rem 0;
      border-radius: 10px;
      padding: 10px 5px;

      a {
        margin-left: 18px;
        color: #A8A8A8;
        font-weight: 600;
        font-size: 1rem;
        transition: .7s all;

        &:hover {
          transform: scale(1.1);
        }
      }

      img {
        margin: 0 !important;
        padding: 0 1rem;
      }
    }

    .btn-whatsapp {
      padding: 15px 12px;
      border: 3px solid transparent;
      font-size: 1.1em;
      border-radius: 10px;
      width: 100%;
      text-decoration: none;
      font-weight: 700;
      color: #000;
      transition: 1s all;
      text-align: center;
      background-color: #00EE00;

      &:hover {
        border-color: #00EE00;
        background-color: transparent;
        color: #00EE00;
      }
    }

    .closeButton {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-color: transparent;
      opacity: 1;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 25px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .StoreModal {

    .StoreInfo {
      max-width: 90% !important;
      min-width: 90% !important;
    }
  }
}

@media screen and (max-width: 460px) {
  .StoreModal {

    .StoreInfo {
      padding: 25px 5px;

      .closeButton {
        top: 10px;
        right: 5px;
      }

      .StoreInfo__address {
        .address_wrapper {
          flex-direction: column;
          gap: 10px;

          p {
            text-align: center;
            margin-left: 0px !important;
          }
        }
      }

      .StoreInfo__contact {
        .contactContainer {
          .phone {
            flex-direction: column;
            gap: 10px;

            a {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}