.containerSaques{
    background-color: #e0dcdc;
    height: 100vh;
    overflow-x: hidden;
    .noContent{
      height: 100vh;
      width: 80%;
      margin: 0 auto;
    }  
    .content{
        background-color: #e0dcdc;
        width: 100%;
        margin-top: 20px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        
       
    
        &.no-margin {
          margin-top: 40px;
        }
    }
}

@media screen and (min-width: 1920px){
  .containerSaques{
    height: 100vh;
    .content{
      margin-left: 20px;
    }
  }
 
}
@media screen and (max-width: 1024px) and (min-width:960px) {
  .containerSaques{
    height: 100vh;
    .content{
      margin-left: 20px;
    }
  }
 
}

@media screen and (max-width: 849px) {
  .containerSaques{
    height: 100vh;
    .noContent{
      margin: 50px auto;
    }  
  }
}

@media screen and (min-width: 742px) {
  .containerSaques {
    .content {
      margin-top: 64px;
    }
  }
}

