.howWorks {
    .title {
        margin-top: 0;
    }

    .index {
        color: #00ee00;
        font-size: 2.5em;
        font-weight: 600;
    }
}

@media screen and (max-width: 1000px) {
    .howWorks {
        .cards {
            flex-direction: column;
            justify-content: center;
        }
    }
}