.StoreCard {
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 15px 25px;
  margin: 0 auto;

  &__image {
    img {
      max-width: 100%;
      width: 350px;
      height: 280px;
      border-radius: 20px;
      // object-fit: cover;
      // object-position: center top;
    }
  }

  &__contact {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    h5 {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    p {
      font-size: 15px;
    }
  }

  .contact__item {
    cursor: pointer;
    margin-left: 1rem;
    border-radius: 4px;
    background-color: #cdcdcd;
    padding: 3px;

    &--whatsapp-icon {
      background-color: #25d366;
    }

    .phone-icon {
      color: #555;
    }

    .whatsapp-icon {
      color: white;
    }
  }

  .segments {
    list-style: none;
    margin: 5px 0;
    padding: 0;
    display: flex;
    gap: 10px;
    width: auto;
    color: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 2px;

    li {
      border-radius: 8px;
      padding: 5px 7px;
      font-size: 1em;
      background-color: #F9F9F9;
      font-weight: 500;
    }
  }

  .more-info-wrapper {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    .btn-whatsapp,
    .btn-more-info {
      padding: 10px 16px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      color: #fff;
      transition: 0.2s;
    }

    .btn-more-info {
      background-color: #00EE00;
      padding: 10px 12px;
      width: 65px;
      height: 65px;
      border-radius: 10px;
      transition: .7s all;

      &:hover {
        opacity: 0.5;
      }
    }

    .btn-whatsapp {
      color: #00EE00;
      font-weight: 600;
      width: 75%;
      height: 65px;
      border-radius: 8px;
      font-size: 1.1em;
      text-decoration: none;
      background-color: #F9F9F9;
      transition: 1s all;

      &:hover {
        background-color: #00EE00;
        color: #F9F9F9;
      }
    }
  }

  // .whatsapp-icon {
  //   display: inline-block;
  //   margin-top: 0.5rem;
  // }
}

@media (max-width: 500px) {
  .StoreCard {
    max-width: 80%;
    min-width: 80%;

    .StoreCard__image {
      img {
        height: auto;
        width: auto;
      }
    }

    .more-info-wrapper {
      flex-direction: column;
      gap: 10px;

      .btn-whatsapp,
      .btn-more-info {
        width: 100%;
      }
    }
  }
}

@media (max-width: 400px) {
  .StoreCard {
    max-width: 95%;
    min-width: 95%;
  }
}