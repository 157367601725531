@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700&display=swap');

.cadastro-clientes {
    span {
        font-size: 0.9em;
        color: red;
    }

    .form-control:focus {
        border-color: #00ee00 !important;
        box-shadow: 0 0 0 0.12rem #00c90080 !important;
    }

    width: 100vw;
    height: 100vh;
    background-image: url(../../assets/img/background.png);
    background-color: rgb(255, 255, 255);
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;

    .container-sm {
        .row {
            .col-4 {
                .topic {
                    h2 {
                        font-size: 1.8em;
                        font-family: 'Sora', sans-serif;
                        font-weight: 700;
                    }
                }

                .h-control {
                    height: 4.2em;

                    span {
                        left: -1.5em;
                        top: -0.5em;
                        position: relative;
                    }
                }

                .mb-3,
                .mb-4 {

                    .form-label {
                        margin-left: 0.5em;
                        font-size: 0.8em;
                        font-family: 'Sora', sans-serif;
                        font-weight: 600;
                    }

                    .form-control {
                        border-color: black;
                        font-size: 0.9em;
                        font-family: 'Sora', sans-serif;
                        font-weight: 400;
                    }

                    .pass-show {
                        color: #000;
                        bottom: 1.45em;
                        font-size: 1.5em;
                        left: 85%;
                        top: -1.4em;
                        position: relative;
                        cursor: pointer;
                    }
                }

                .mt-4-5 {
                    margin-top: 2.1em !important;
                }

                .ms-2-2 {
                    margin-left: 2.2em;
                }

                .btn-success {
                    font-family: 'Sora', sans-serif;
                    font-size: 0.9em;
                    font-weight: 800;
                    padding: 0.8em;
                    color: black;
                    background-color: #00ee00;
                    width: 80%;
                    border-radius: 1.5em;
                    border: none;
                }
            }
        }
    }

    a {
        font-weight: 700;
        cursor: pointer;
    }

    .form-select {
        border-color: black;
    }

    .detais-text {
        position: relative;
        display: inline-block;
        font-size: 0.6rem;
        margin: 8px;
    }

    .detais-text::before,
    .detais-text::after {
        --bs-text-opacity: 1;
        content: "";
        position: absolute;
        top: 0.9em;
        width: 7.5em;
        height: 0.09em;
        background-color: #6c757d;
        left: -8.25em;
    }

    .detais-text::after {
        left: initial;
        right: -8.25em;
    }

    .img-fluid {
        max-width: 60% !important;
        margin-left: 18%;
    }

    .border-dashed {
        border-color: #008f00;
        border-style: dashed;
        padding: 0.8em;
    }

    .agree-confirm {
        font-family: 'Sora', sans-serif;
        font-weight: 400;
        font-size: 0.8em;
    }



    .already-have-account {
        font-size: 0.8em;
    }

    .already-have-account {
        a {
            font-weight: 800;
            color: black;
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 800px) {
    .cadastro-clientes {
        background-image: none;

        .container-sm {

            #responsivity-control {
                display: grid;
            }

            #lg-image {
                display: none;
            }

            .row {
                .col-4 {
                    flex: 0 0 auto;
                    width: 100%;
                }

                .right-form {
                    margin-left: 0 !important;
                }
            }
        }

    }

}