.UserMessageContainer {
    background-color: var(--gray);
    border-radius: 1.5rem;
    color: #000;
}

.BotMessageContainer {
    background-color: var(--secondary-green);
    border-radius: 1.5rem;
    color: #fff;
}

.testResponse {
    display: inline;
    white-space: pre-wrap;
}

.textResponse {
    font-size: 0.812rem;
}
