.VerificacaoWrapper{
    height: 100vh;
    background-color: #e0dcdc;
}
.VerificacaoBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.LogoWrapper img{
    margin: 15px 0px;
    width: 100px;
    height: 100px;
    margin-top: 40px;
}
.SmsCodeWrapper{
    background: white;
    padding: 24px;
    text-align: center;
    width: 60%;
    border-radius: 15px;
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5);
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5); 
}
.SmsCodeWrapper p{
    margin: 0;
}
.SmsCodeWrapper input{
    margin: 10px 0px;
}
.ButtonGroup{
    display: flex;
    flex-direction: column;
}
.ButtonGroup button{
    margin: 10px 0px;
}
.btnSendCode{
    border: none;
    background-color: #11c76f;
    height: 35px;
    color: white;
    border-radius: 5px;
    transition: ease-in-out 0.2s;
}
.btnSendCode:hover{
    transition: ease-in-out 0.2s;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}
.btnSendCode:disabled{
    background-color: gray;
}
.btnResendCode{
    border: none;
    text-decoration: underline;
    background-color: white;
    color: blue;
    width: 200px;
    align-self: center;
}
.btnResendCode:disabled{
    text-decoration: underline;
    color: gray;
    width: 200px;
    align-self: center;
}


@media screen and (max-width:768px) {
    .VerificacaoBox{
        /* width: 600px;
        left: calc(50% - 300px); */
    }
    .SmsCodeWrapper{
        text-align: center;
        width: 80%;
    }
}