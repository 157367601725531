@import url("https://fonts.googleapis.com/css2?family=Sora:wght@700&display=swap");

.ModalCadastro {
    position: fixed;
    z-index: 9000;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, 0.6);
    text-align: center;

    /*  .closeButton {
        position: relative;
        color: #000;
        bottom: 260px;
        left: 15.3rem;
        background-color: transparent;
        opacity: 1;
        border: none;
        outline: none;
        border-radius: 70%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;

        @media screen and (max-width: 768px) {
            bottom: 17rem;
            left: 11.4rem;
        }

        @media screen and (max-width: 430px) {
            bottom: 16.7rem;
            left: 7.9rem;
        }

        svg {
            width: 20px;
        }
    }
 */
    .ModalWrapper {
        position: absolute;
        width: 550px;
        padding-top: 0.5rem;
        padding-bottom: 2rem;
        background: #ffffff;
        border: none;
        box-sizing: border-box;
        border-radius: 20px;

        @media screen and (max-width: 768px) {
            width: 420px;
        }

        @media screen and (max-width: 430px) {
            width: 300px;
        }

        .relativePositionButton {
            position: relative;

            .closeButton {
                position: absolute;
                color: #000;
                //top: 5px;
                right: 8px;
                background-color: transparent;
                opacity: 1;
                border: none;
                outline: none;
                border-radius: 70%;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 999;

                // @media screen and (max-width: 768px) {
                //     bottom: 22.5rem;
                //     left: 11.4rem;
                // }

                // @media screen and (max-width: 430px) {
                //     bottom: 21.9rem;
                //     left: 7.9rem;
                // }

                svg {
                    width: 20px;
                }
            }
        }

        .headerModal {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            border-radius: 15px 15px 0px 0px;
            align-items: center;
            justify-content: center;
        }

        .CadInfo {
            color: #409900;
            line-height: 16px;

            .labelCad {
                font-weight: bold;
                color: #000;
                font-family: Sora;
            }
        }

        .CadInfo > h2 {
            font-weight: 700 !important;
            font-size: 30px !important;
            font-family: Sora !important;

            @media screen and (max-width: 430px) {
                font-size: 22px !important;
            }
        }

        .StepTracker {
            max-width: 275px;
            max-height: 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 20px auto 20px auto;

            .Step1 {
                max-width: 80px;

                p {
                    font-family: Sora;
                    font-size: 10.5px;
                    color: black;
                    font-weight: bold;
                }

                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .Step2Gray {
                max-width: 80px;

                p {
                    font-family: Sora;
                    font-size: 10.5px;
                    color: black;
                    font-weight: 700 !important;
                }

                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .Step2Green {
                max-width: 80px;

                p {
                    font-family: Sora;
                    font-size: 10.5px;
                    color: black;
                    font-weight: 700 !important;
                }

                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .groupHeader {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            max-width: 345px;

            @media screen and (max-width: 768px) {
                margin-bottom: 10px;
                max-width: 300px;
            }

            @media screen and (max-width: 430px) {
                max-width: 250px;
            }

            .formInput {
                height: 40px;
                padding: 8px;
                font-size: 16px;
                text-align: center;
                font-family: Sora;
                font-weight: 700;
                outline: none;
                width: 100%;
                border-radius: 7.5px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                border: 1.5px solid #000;
            }

            .formInput:focus {
                border: 2px solid #00ee00;
            }
        }

        .groupHeader > label {
            width: 100%;
            margin: 0;
            margin-left: 10px;
            text-align: left !important;
        }

        .groupHeader > label > span {
            width: 100%;
            font-size: 12.5px;
            color: black;
            font-weight: 700;
            font-family: Sora !important;
            text-align: left !important;
        }

        .Salesform {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0 16px;

            label {
                font-weight: bold;
            }
        }

        .button {
            width: 100%;
            border-radius: 5px;
            border: none;
            user-select: none;
            text-transform: uppercase;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:disabled {
                opacity: 0.75;
                cursor: wait;
            }

            .progress {
                background: none;
                margin-left: 8px;
            }
        }

        .AlertKobe {
            margin-right: auto;
            margin-left: auto;
            display: flex;
            align-items: center;
            max-width: 290px;
            //height: 60px;

            #strong {
                color: #00ee00;
            }

            p {
                font-size: 15px;
                font-family: Sora;
                font-weight: 700;
            }

            @media screen and (max-width: 768px) {
                // height: 40px;
                margin: 1rem 0;
            }
        }

        .buttonGroup {
            width: 100%;
            display: flex;
            margin-top: 1rem;
            align-items: center;
            justify-content: center;

            .buttonConfirm {
                width: 80%;
                margin: 0 auto;
                height: 40px;
                color: black;
                font-family: Sora;
                font-weight: bold;
                background: #00ee00;
                box-shadow: 0px 13.4869px 20.2304px rgba(0, 0, 0, 0.14),
                    0px 5.05759px 25.288px rgba(0, 0, 0, 0.12),
                    0px 6.74346px 8.42932px rgba(0, 0, 0, 0.2);
                border: none;
                border-radius: 20px;
                border: 2px solid #00ee00;
                transition: 0.8s all;

                &:hover {
                    background-color: #fff;
                    color: #00ee00;
                }

                @media screen and (max-width: 768px) {
                    width: 80%;
                }
            }

            .buttonCancel {
                padding: 0.5rem 1rem;
                text-align: center;
                background: #fff;
                box-shadow: 0px 10.7429px 16.1143px rgba(0, 0, 0, 0.14),
                    0px 4.02857px 20.1429px rgba(0, 0, 0, 0.12),
                    0px 5.37143px 6.71429px rgba(0, 0, 0, 0.2);
                color: #000;
                border: none;
                border-radius: 20px;
                transition: all 0.2s ease-in-out;

                svg {
                    font-size: 20px;
                }
            }

            .buttonCancel:hover {
                transition: all 0.4s ease-in-out;
                background-color: #fff;
                color: #00ee00;
                transform: scale(1.01);
            }
        }

        p {
            font-size: 14px;
            font-weight: bold;
            margin: initial;
        }

        h1 {
            margin-top: 4px;
            font-weight: 800;
        }

        .progress {
            width: 36px;
            height: 36px;
        }

        .row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: center;
            margin-left: auto;
            gap: 5px;
            margin-right: auto;
            width: 100%;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        .group {
            width: 100%;
            margin-bottom: 20px;
            font-size: 14px;
            padding: 0;

            @media screen and (max-width: 768px) {
                width: 300px;
                margin-bottom: 10px;
            }

            @media screen and (max-width: 430px) {
                width: 250px;
                margin-bottom: 10px;
            }

            label {
                font-size: 0.75rem;
            }

            span {
                font-size: 0.75rem;
            }

            .formInput {
                height: 40px;
                padding: 8px;
                font-size: 16px;
                text-align: left;
                border-radius: 7.5px;
                border: 1px solid #c6c8c9;
            }

            .formInputValue {
                border-radius: 7.5px;
                border: 1.5px solid #000;
                padding: 8px;
                font-weight: 700;
                font-family: Sora;
                text-align: center;
                font-size: 16px;
                outline: none;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                width: 100%;

                @media screen and (max-width: 768px) {
                    width: 300px;
                }

                @media screen and (max-width: 430px) {
                    width: 250px;
                }
            }

            .formInputValue:focus {
                border: 2px solid #00ee00;
            }
        }

        .group > p {
            font-size: 14px !important;
            color: #000;
            text-align: left;
            margin-left: 10px;
            font-weight: bold;
            font-family: Sora;
        }

        .clienteCard {
            display: flex;
            flex-direction: column;
            margin: auto;
            align-items: center;
            // height: 208px;
            width: 300px;
            border: black 1px solid;
            border-radius: 10px;

            @media screen and (max-width: 768px) {
                width: 320px;
                // height: 208px;
            }

            @media screen and (max-width: 430px) {
                width: 250px;
                // height: 208px;
            }
        }

        .clienteInfo {
            color: #0acc0a;
            display: flex;
            flex-direction: column;
            padding: 24px 10px 10px;
            width: 100%;
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;

            label {
                font-family: Sora !important;
                font-weight: 700;
                font-size: 17px;
            }
        }

        .compraInfo {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 8px;

            .colEsq {
                display: flex;
                text-align: left;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;

                label {
                    font-family: Sora !important;
                    font-weight: 700;
                }
            }

            .colDir {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 50%;

                label {
                    font-family: Sora !important;
                    font-weight: 700;
                }
            }
        }

        .totalInfo {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 8px;

            .colEsq {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;

                label {
                    font-family: Sora !important;
                    font-weight: 700;
                }
            }

            .colDir {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 50%;
            }

            .cashback-value {
                font-family: Sora !important;
                font-weight: 700;
                color: #0acc0a;
            }
        }

        .senhaInput {
            margin-top: 10px;
            padding: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            justify-content: space-evenly;

            label {
                font-size: 13pt;
                font-family: Sora;
                color: #000;
                font-weight: 700;
                margin-left: 5px;
            }

            .inputShowPass {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                padding: 5px;
                border-radius: 8px;
                border: 1px solid black;

                input {
                    border: none;
                    outline: none;
                    width: 100%;
                    border-radius: 5px;
                    font-family: Sora;
                    padding: 5px;
                    font-weight: bold;
                }

                img {
                    cursor: pointer;
                }
            }
        }
    }
}
