.tudoSaque {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #05fe9f 0%, #137a53 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  .containerSaque {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    width: 386px;
    height: 454px;
    background-color: #fff;
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
      0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    overflow-x: hidden;

    h1 {
      margin-top: 18px;
    }

    .formSaque {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 18px;

      .group {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 5px;

        label {
          font-size: 0.75rem;
        }
        span {
          font-size: 0.75rem;
        }
        .campoText {
          width: 284px;
          height: 41px;
          border-radius: 7.54px;
          border: 1px solid #c6c8c9;
        }
        .campoTextSaldo {
          width: 140px;
          height: 41px;
          border-radius: 7.54px;
          border: 1px solid #c6c8c9;
          margin-top: 18px;
        }
        .buttonConfirm {
          width: 161px;
          height: 35.4px;
          background: #009c60;
          box-shadow: 0px 13.4869px 20.2304px rgba(0, 0, 0, 0.14),
            0px 5.05759px 25.288px rgba(0, 0, 0, 0.12),
            0px 6.74346px 8.42932px rgba(0, 0, 0, 0.2);
          border-radius: 5.90052px;
          color: #fff;
          border: none;
          margin-top: 36px;
        }
        .buttonCancel {
          width: 113px;
          height: 28px;

          background: #9c0000;
          box-shadow: 0px 10.7429px 16.1143px rgba(0, 0, 0, 0.14),
            0px 4.02857px 20.1429px rgba(0, 0, 0, 0.12),
            0px 5.37143px 6.71429px rgba(0, 0, 0, 0.2);
          border-radius: 4.7px;
          color: #fff;
          border: none;

          margin-top: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .tudoSaque {
    .containerSaque {
      max-width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      .group {
        max-width: 95%;
        .campoText {
          max-width: 100%;
        }
        .campoTextSaldo {
          max-width: 100%;
        }
        .buttonConfirm {
          max-width: 100%;
        }
        .buttonCancel {
          max-width: 100%;
        }
      }
    }
  }
}
