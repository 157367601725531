@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");

.btn-visualizar-vendas-avulsas {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    max-width: 300px;
    height: 40px;
    font-weight: bold;
    font-family: Sora;
    color: white;
    font-size: 18px;
    background-color: #0acc0a;
    border: none;
    transition: 0.5s all;
}

.btn-editar-vendas-avulsas {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    max-width: 200px;
    height: 40px;
    font-weight: bold;
    font-family: Sora;
    color: white;
    font-size: 18px;
    background-color: #85e;
    border: none;
    transition: 0.5s all;
}

.Mt {
    margin-top: 60px !important;
    gap: 25px !important;

    .AlingTwoTable>h2 {
        font-size: 1.1em !important;
    }

    &.NewCategoryWrapper {
        margin-top: 30px !important;
    }
}

.width {
    width: 75vw !important;
    margin-top: 100px !important;
}

.Client {
    width: 100% !important;
    margin: 0 !important;
    min-height: 720px !important;

    // &:hover {
    //     transform: scale(1.02) !important;
    // }

    .AlingTwoTable {
        .cashCon {
            width: 170px;
            background-color: #8cff8c;
            padding: 7px !important;
            color: rgba(0, 0, 0, 0.782);
            border-radius: 15px;
        }
    }

    .AlingTwoTableDep {
        #comissao {
            width: 170px;
        }
    }
}

.Table {
    display: flex;
    background-color: white;
    width: 93vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    max-width: 1500px;
    gap: 40px;
    overflow-x: auto;
    margin-bottom: 50px !important;
    overflow: auto;
    flex-direction: column;
    border-radius: 30px;
    padding: 40px;
    margin: 0 auto 50px auto;
    transition: 1.4s all;
    position: relative;

    // &:hover {
    //     transform: scale(1.05);
    // }

    .swipe {
        position: absolute;
        left: 5px;
        display: none;
        bottom: 0px;
    }

    .StoreCategoriesTableHeader {
        h2 {
            flex: 1;
            max-width: 33.3%;
        }
    }

    .AlingOneTable {
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .Active {
            display: flex !important;
        }

        .conclu {
            display: none;
            align-items: center;
            gap: 10px;

            #ballC {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #8cf78c;
            }
        }

        .conclu>h2 {
            margin: 0;
            font-family: Sora;
            font-size: 18px;
            font-weight: bold;
        }

        .Pend {
            display: none;
            align-items: center;
            gap: 10px;

            #ballP {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #f78c8c;
            }
        }

        .Pend>h2 {
            margin: 0;
            font-family: Sora;
            font-size: 18px;
            font-weight: bold;
        }

        .cont {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .boxFilt {
            width: 170px;
            height: 60px;
            display: flex;
            background-color: #fff;
            justify-content: space-around;

            align-items: center;
            border: 3px solid #c7c7c7;
        }

        .boxFilt>h4 {
            margin: 0;
            font-size: 20px;
            color: black;
            font-weight: bold;
            font-family: Sora;
        }
    }

    .AlingOneTable>h2 {
        font-family: Sora;
        margin: 0;
        font-size: 25px;
        color: #a6a6a6;
        font-weight: bold;
    }

    .AlingTwoTable {
        display: flex;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        .category-name,
        .btn-editar-vendas-avulsas,
        .btn-visualizar-vendas-avulsas {
            flex: 1;
            max-width: 33.3%;
        }

        #preco {
            width: 160px;
            text-align: center;
        }

        #cash {
            width: 160px;
            text-align: center;
        }

        .statusCashConfir {
            display: flex;
            background-color: #4ee54e;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 160px;
            border: 3px solid #4ee54e;
            height: 50px;
            transition: 1s all;


            &:hover {
                background-color: #fff;
            }

            &:hover p {
                color: #4ee54e;
            }
        }

        .statusCashConfir>p {
            margin: 0;
            transition: 1s all;
            font-weight: 700;
            font-family: Sora;
            font-size: 1em;
            margin: 0;
            color: black;
        }

        .statusCashPenden {
            display: flex;
            background-color: #fe5555;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 160px;
            border: 3px solid #fe5555;
            height: 50px;
            transition: 1s all;


            &:hover {
                background-color: #fff;
            }

            &:hover p {
                color: #fe5555;
            }
        }

        .w250 {
            width: 250px !important;
            font-size: 1.55em;
        }

        .pad {
            font-size: 1.3em !important;
            height: 60px;
            display: flex !important;
        }

        .statusCashPenden>p {
            margin: 0;
            transition: 1s all;
            font-weight: 700;
            font-family: Sora;
            font-size: 1em;
            margin: 0;
            color: black;
        }
    }

    .text {
        display: flex;
        width: 100%;
        justify-content: center;

        p {
            font-weight: 600;
            font-size: 1.2em;
        }
    }

    .AlingTwoTableDep {
        display: flex;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        h2 {
            font-family: Sora;
            font-size: 1.5em;
            margin: 0;
            color: black;
            width: 250px;
            text-align: center;
            font-weight: bold;
        }
    }

    .bold {
        h2 {
            font-weight: 700 !important;
        }
    }

    .AlingTwoTable>h2 {
        font-family: Sora;
        font-size: 1.3em;
        margin: 0;
        color: black;
        width: 160px;
        text-align: center;
        font-weight: 500;
    }
}

@media screen and (max-width: 1000px) {
    .widt {
        width: 90vw !important;
    }

    .Table {
        .swipe {
            display: initial;
        }

        .AlingOneTable {
            min-width: 850px;

            .conclu {
                display: flex;
            }

            .Pend {
                display: flex;
            }

            .cont {
                .boxFilt {
                    width: 130px;
                }

                .boxFilt>img {
                    width: 30px !important;
                    height: 14px !important;
                }
            }
        }

        .AlingTwoTableDep {
            min-width: 850px !important;

            h2 {
                font-size: 1.3em;
                width: 210px;
            }
        }

        .AlingTwoTable {
            min-width: 850px !important;

            .statusCashPenden {
                display: none;
            }

            .statusCashConfir {
                display: none;
            }

            .w250 {
                width: 210px !important;
                font-size: 1.3em;
            }

            .cashCon {
                background-color: #8cff8c;
                padding: 7px;
                color: rgba(0, 0, 0, 0.782);
                border-radius: 15px;
            }

            .cashPen {
                background-color: #ff8c8c;
                padding: 7px;
                color: rgba(0, 0, 0, 0.782);
                border-radius: 15px;
            }

            #dnone {
                display: none;
            }

            #cash {
                min-width: 100px !important;
            }
        }

        .AlingTwoTable>h2 {
            min-width: 150px !important;
            font-size: 17px;
        }
    }
}