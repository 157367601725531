.NotFound {
    height: 100vh;
    background-color: #e0dcdc;
    display: flex;
    padding-top: 40px;
    justify-content: center;
    position: relative;

    .container {
        margin: 0 auto;
        max-width: 90%;
    }

    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 125px);
        display: block;
        background: url("../../assets/logoCircular.png") no-repeat center center /
            250px 250px;
        width: 250px;
        height: 250px;
        filter: opacity(0.1);
    }
}

@media screen and (min-width: 768px) {
    .NotFound {
        &::after {
            top: calc(50% - 200px);
            background-size: 400px 400px;
            width: 400px;
            height: 400px;
        }
    }
}

@media screen and (min-width: 849px) {
    .NotFound {
        padding-top: 80px;

        h1 {
            font-size: 3rem;
        }

        p {
            font-size: 1.5rem;
        }

        a {
            font-size: 1.2rem;
            text-decoration: none;
        }
    }
}
