.DefaultBanner {
    width: 100%;
    height: fit-content;
    background-color: #00c92a;
    // background-image: url(../../assets/img/bannerEffect.png);
    // background-size: cover;

    .bannerText {
        color: #FFF;
        font-family: "Sora", sans-serif;

        h1 {
            font-weight: 600 !important;
        }
    }
}