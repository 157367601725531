.statisticContainer {
    width: 95%;
    justify-content: space-between;
    // display: flex;
    padding: 30px;
    border-radius: 15px;
    background-color: white;

    .statisticInfo {
        display: flex;

        .swipe {
            display: none;
        }

        .containerAdvances {
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 20vw;


            .boxAdvances {
                display: flex;
                gap: 30px;
                min-width: 240px;
                max-width: 240px;
                justify-tracks: center;
                align-items: center;

                .imgElement {
                    //background-color: #00ee0034;
                    display: flex;
                    height: 60px;
                    min-width: 60px;
                    border-radius: 10px;
                    justify-content: center;
                    align-items: center;
                }

                .imgLucreiElement {
                    background-color: #00ee0034;
                }

                .imgAvulseElement {
                    background-color: #5c049c;
                }

                .textAdvances {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    font-family: Sora;
                }

                .textAdvances>h3 {
                    margin: 0;
                    font-size: 20px;
                    font-weight: bold;

                }

                .textAdvances>p {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }

        .containerGraphic {
            display: flex;
            width: 40vw;
            gap: 20px;
            justify-content: center;

            .leftBoxGraph {
                font-family: Sora;
                display: flex;
                height: 50%;
                margin: auto auto;
                flex-direction: column;
                justify-content: flex-start;
                text-align: center;
                width: auto;

                #textHigher {
                    font-weight: bold;
                    font-size: 23px;
                }
            }

            .rightBoxGraph {
                font-family: Sora;
                display: flex;
                height: 50%;
                margin: auto auto;
                flex-direction: column;
                justify-content: flex-start;
                text-align: center;

                #textHigher {
                    font-weight: bold;
                    font-size: 23px;
                }

                .statisticGraphic {
                    margin: auto;

                    .BoxDataGraph {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        justify-content: flex-start;

                        .caption {
                            min-width: 15px;
                            min-height: 15px;

                            border-radius: 50%;
                        }

                        .captionLucreiPrev {
                            background-color: #409900;
                        }

                        .captionLucreiCurr {
                            background-color: #00EE00;
                        }

                        .captionAvulsePrev {
                            background-color: #5c049c;
                        }

                        .captionAvulseCurr {
                            background-color: #9000f7;
                        }

                        .groupInfor {
                            height: 100px;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            text-align: left;
                        }

                        .groupInfor>p {
                            margin: 0;
                            font-family: Sora;
                            font-size: 0.8em;
                            font-weight: bold;
                        }

                        .groupInfor>h3 {
                            font-family: Sora;
                            margin: 0;
                            font-weight: bold;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .statisticContainer {
        .statisticInfo {
            justify-content: space-between;
            gap: 20px;

            .containerAdvances {
                width: 30%;
            }

            .containerGraphic {
                width: 70%;
                gap: 20px;
            }
        }
    }

}

@media screen and (max-width: 900px) {
    .statisticContainer {
        .statisticInfo {
            flex-direction: column;
            justify-content: space-between;


            .containerAdvances {
                width: 100%;
                margin-bottom: 10px;
                flex-direction: initial;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .containerGraphic {
                display: flex;
                width: 100%;
                gap: 20px;
                flex-wrap: wrap;
            }
        }
    }
}

@media screen and (max-width: 580px) {
    .statisticContainer {
        .statisticInfo {
            overflow: auto;
            position: relative;

            .swipe {
                position: absolute;
                display: initial;
                top: 100px;
                right: 5px;
            }

            .containerAdvances {
                overflow: auto;
                padding: 15px 0;


                #total {
                    min-width: 250px;
                    max-width: 250px;
                }
            }
        }
    }

}

@media screen and (max-width: 435px) {
    .containerGraphic {
        flex-direction: column;
        align-items: center;
    }
}