.ResetPasswordPage {
  background-color: #e0dcdc;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;

  .logo {
    width: 7.5rem;
    margin: 0 auto 2rem auto;
  }

  .content {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #fff;
    width: 90%;
    max-width: 768px;
  }

  .invalid-token-message {
    margin-top: 2rem;
    background-color: #fff;
  }

  h1 {
    font-size: 2rem;
  }

  .campo {
    width: 100%;
    height: 41px;
    border: 1px solid #c6c8c9;
    border-radius: 7.5px;
    padding: 8px;
    font-size: 16px;
    outline: none;

    &:focus {
      background: #fff;
      box-shadow: 0 0 0 3px #05fe9f;
    }
  }

  .toggle-password-wrapper {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
  }

  .toggle-password {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #0d6efd;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .btn-update-password {
    width: 100%;
    padding: 8px 12px;
    background: #009c60;
    color: #fff;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 0.875rem;
    font-family: "Montserrat-Light";
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-message {
    align-self: flex-start !important;
    font-size: 0.75rem;
    color: #f44336;
    margin-left: 0;
  }
}
