#empresasParceiras {
    background: #f9f9f9;
    border-radius: 1rem;
}

#InfoWrapper {
    display: grid;
    z-index: 1;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 0.5rem;
    justify-content: center;
}

#InfoRowEmpresas {
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: "col1" "col2";
}

#Column1 {
    padding: 0 15px;
    grid-area: col1;
}

#Column2 {
    margin-bottom: 15px;
    grid-area: col2;
}

#Heading {
    color: black;
    letter-spacing: 1.1px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
}

#ImgWrap {
    max-width: 555px;
    height: 100%;
}

#Img {
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
}

.imgEmpresas-Viewer-Slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.imgEmpresas-Viewer-Grid {
    justify-content: center;
    align-items: center;
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
}

.imgEmpresas-Viewer-Row {
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: "col1 col2";
}

.imgEmpresas-Viewer-Column1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding: 0px 15px;
    grid-area: col1;
}

.imgEmpresas-Viewer-Column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding: 0px 15px;
    grid-area: col2;
}

/* Css Teste */
#containerSlider2 {
    max-width: 75vw;
}

#containerSlider2 .carrosel {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
}

#containerSlider2 .carrosel::-webkit-scrollbar {
    display: none;
}

#containerSlider2 .carrosel .item {
    background-color: rgba(255, 255, 255, 0.829);
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px;
    width: 280px;
    border-radius: 16px;
    flex: none;
}

#containerSlider2 .carrosel .item .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#containerSlider2 .carrosel .item .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#containerSlider2 .carrosel .item .info span {
    display: block;
    text-align: center;
    color: #1e1e1e;
    padding: 5px;
    border-radius: 10px;
}

#containerSlider2 .controladores {
    width: 100%;
    text-align: center;
}

#containerSlider2 .controladores button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin: 5px 10px;
}

@media screen and (max-width: 768px) {
    #InfoContainer {
        padding: 100px 0;
    }

    #InfoRowEmpresas {
        grid-template-areas: "col1" "col2";
    }

    #Heading {
        font-size: 32px;
    }
}