.depoimentsSection {

    .opinionCards {
        display: flex;
        gap: 1rem;

        .opinionCard {
            background-color: #f5f5f5;
            padding: 3rem 2rem;
            border-radius: 1rem;
            width: 25%;
        }
    }
}

@media screen and (max-width: 700px) {
    .depoimentsSection {
        width: 90%;
        margin: auto;

        .opinionCards {
            flex-direction: column;

            .opinionCard {
                width: 100%;
            }
        }
    }
}