.changePass-btn {
    height: 2.3rem;
}

.form-control:focus {
    border-color: rgb(17, 199, 111) !important;
    box-shadow: 0 0 0 0.12rem rgb(17, 199, 111);
}

@media screen and (max-width: 700px) {
    .pass-control {
        flex-direction: column;
    }
}