@import url("../../assets/Fonts/Fonts.css");
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300&display=swap');

$Gradiente_Fundo_body: linear-gradient(90deg, #009c60 50%, #73b671 100%);
$Gradiente_Fundo_descricao: linear-gradient(90deg, #00e88f 0%, #009c60 114.75%);
$Cor_Sombra: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12),
  0px 8px 10px rgba(0, 0, 0, 0.2);
$Cor_Fundo_Box_Left: #fff;
$Cor_Borda_Campos: #c6c8c9;
$Cor_Botao_Entrar: #009c60;
$Cor_Hover_Botao_Entrar: #5da052;

#box {
  border-radius: 20px;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  box-shadow: $Cor_Sombra;

  #box-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50vw;
    min-height: 100vh;
    height: auto;
    background: #F8F8F9;

    .spanNotAccount {
      color: #707273;
    }

    form {
      height: 400px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      // margin-top: 50px;

      span {
        align-self: flex-start !important;
        font-size: 0.75rem;
      }

      .erros {
        font-size: 0.7rem;
        color: red;
      }

      h5 {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 20px;
        font-family: "Montserrat-Light";
      }

      .form-group {
        .esquecerSenha {
          text-decoration: underline;
          margin-top: 10px;
          cursor: pointer;
          width: 130px;
          font-weight: bold;
          color: black;
          font-family: "Sora";
          font-size: 12px;
          transition-duration: 1s;

          &:hover {
            transition-duration: 1s;
            color: #00EE00;
          }
        }

        .cadastrar {
          margin-top: 10px;
          cursor: pointer;
          text-align: center;
          font-weight: bold;
          color: black;
          font-family: "Sora";
          font-size: 12px;
          transition-duration: 1s;

          &:hover {
            transition-duration: 1s;
            color: #00EE00;
          }
        }

        label {
          font-family: "Montserrat-Light";
        }

        .campo {
          font-family: Sora;
          font-size: 12px;
          font-weight: bold;

          .ButtonIn {
            cursor: default;
            outline: none;
          }

          .ButtonIn:hover {
            background-color: transparent;
          }

          .ButtonIn:active {
            transition: none;
          }
        }
      }

      .button_Entrar {
        width: 100%;
        padding: 8px 12px;
        background: #00EE00;
        color: #000000;
        font-weight: bold;
        border: 2px solid transparent;
        border-radius: 20px;
        cursor: pointer;
        font-size: 0.875rem;
        font-family: "Sora";
        display: flex;
        align-items: center;
        outline: none;
        justify-content: center;
        transition-duration: 1s;

        &:hover {
          border: 2px solid #00EE00;
          transition-duration: 1.4s;
          background: white;
        }

        &:disabled {
          opacity: 0.7;
          cursor: wait;
        }
      }
    }
  }

  #box-left {
    width: 50vw;
    display: flex;
    justify-content: center;
    height: 100vh;
    background: #F8F8F9;
    flex-direction: column;
    padding-top: 4.0625rem;

    h5 {
      // margin: 6% auto;
      text-align: center;
      font-size: 1rem;
      font-family: "Montserrat-Medium";
      font-weight: bold;
    }

    p {
      font-size: 0.875rem;
    }

    label {
      font-size: 0.875rem;
    }

    #carouselExampleIndicators {
      display: flex;
      height: 550px;

      .carousel-indicators>li {
        width: 10px;
        height: 10px;
        background-color: white;
        border: 1.5px solid black;
        border-radius: 50%;
      }

      .carousel-indicators {
        .active {
          background: black;
        }
      }

      .carousel-inner {
        .carousel-item {
          .containerCarousel {
            gap: 30px;
            height: 500px;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .textCarousel {
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              gap: 20px;
              flex-direction: column;
            }

            .textCarousel>h5 {
              font-size: 25px;
              width: 80%;
              font-family: Sora;
              font-weight: 700;
              color: #000000;
            }

            .textCarousel>p {
              font-size: 14px;
              font-family: Sora;
              text-align: center;
              font-weight: 400;
              opacity: 50%;
              width: 60%;
              color: #000000;
            }
          }

          .containerCarousel>img {
            height: 280px;
          }

          #imgL {
            height: 290px;
          }
        }
      }
    }
  }

  .LoginForm {
    width: 73%;
    height: auto;
    padding: 0 16px;

    .d-flex {
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 20px;
      flex-direction: column;
      margin-bottom: 10px;
      width: 100%;

      .DnTwo {
        display: none;
      }

      #textCompr {
        font-weight: bold;
        font-size: 30px;
        color: black;
        font-family: Sora;
      }

      .textCenter {
        width: 80%;
        display: flex;
        justify-content: center;
        height: 7px;
        opacity: 50%;
        border-bottom: 2px solid grey;
        text-decoration: dashed;

        #alingT {
          font-size: 10px;
          font-weight: bold;
          font-family: Sora;
          width: 155px;
          height: 15px;
          color: #000000;
          background-color: #F8F8F9;
        }
      }
    }

    .showPass {
      width: 100px;
    }


    .form-group {
      width: 100%;
      margin-bottom: 16px;

      #fontLabel {
        font-family: Sora;
      }

      label {
        font-size: 13px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 2px;
      }

      input {
        width: 100%;
        outline: none;
        border: none;

        &:focus {
          background: #fff;
          box-shadow: none;
          border: none;
        }

        &:valid {
          box-shadow: none;
        }

      }
    }

    .error-message {
      color: #f44336;
      font-family: Sora;
      font-weight: 600;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 870px) {
  #box {
    #box-left {
      #carouselExampleIndicators {
        .carousel-inner {
          .carousel-item {
            .containerCarousel {
              .textCarousel>h5 {
                width: 80%;
              }

              .textCarousel>p {
                font-size: 12px;
                font-family: Sora;
                text-align: center;
                font-weight: 400;
                opacity: 50%;
                width: 55%;
                color: #000000;
              }

              .textCarousel>p {
                width: 70%;
              }
            }

            .containerCarousel>img {
              height: 240px;
            }

            #imgL {
              height: 260px;
            }
          }
        }
      }
    }

    #box-right {
      .LoginForm {
        height: auto;
        width: 90%;
        padding: 0 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #logo {
    margin-top: 30px;
  }

  #box {
    width: 510px;

    flex-direction: column;

    #box-right {
      padding: 24px;
      width: 100vw;
      border-radius: 0px;
      height: auto;

      .LoginForm {
        height: auto;
        width: 90%;
        padding: 0 16px;

        .formm {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .button_Entrar {
            width: 50vw;
            border-radius: 8px;
          }
        }

        .d-flex {
          gap: 10px;

          .textCenter {
            width: 75vw;
            height: 6.5px;
            opacity: 60%;

            #alingT {
              font-size: 10px;
              width: 160px;
            }
          }

          .cell {
            height: 350px;
          }

          .LucreiM {
            height: 70px;
          }

          .Dn {
            display: none;
          }

          .DnTwo {
            display: initial;
          }
        }
      }
    }

    #box-left {
      display: none;
    }

  }
}

@media screen and (max-width: 520px) {
  #logo {
    margin-bottom: 1rem;
    width: 200px;
  }

  #box {
    width: 100vw;

    #box-right {
      width: 100%;
      height: auto;

      .LoginForm {
        height: auto;

        .formm {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .button_Entrar {
            width: 70vw;
            border-radius: 8px;
          }
        }

        .d-flex {
          gap: 10px;

          .textCenter {
            width: 75vw;
            height: 6.5px;
            opacity: 60%;

            #alingT {
              font-size: 10px;
              width: 160px;
            }

          }

          .cell {
            width: 100%;
            height: auto;
          }

          .LucreiM {
            height: 50px;
          }
        }
      }
    }

  }

  .customModal {
    max-width: 300px;
    width: 100%;
    color: rgba(255, 0, 0, 0.726);
  }

}