@import url("https://fonts.googleapis.com/css2?family=Sora:wght@700&display=swap");

.modalProducts {
    height: 70vh;
    width: 100%;
    // max-height: 100vh;
    height: 100%;
    overflow-y: auto;
    // width: 420px;
    top: 0;
    padding: 28px 22px;
    z-index: 10000;
    background-color: #fff;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    position: fixed;
    right: -800%;
    transition: 0.7s all;

    @media screen and (max-width: 500px) {
        width: 100%;
        right: -100%;
    }

    .headerModalCategory {
        display: flex;
        justify-content: flex-end;

        img {
            transition: 0.5s all;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    h2 {
        font-weight: 700;
        margin: 14px 0px 0px 0px;
    }

    .sectionBoxCategorys {
        margin-top: 20px;

        .boxCategory {
            display: flex;
            padding: 15px 0px;
            border-bottom: 4px solid #ececec;
            justify-content: space-between;

            label {
                color: #000;
                cursor: pointer;
                font-size: 1.2em;
                font-weight: 700;
            }

            input {
                all: unset;
                transition: 0.3s all;
                cursor: pointer;
                width: 15px;
                display: flex;
                justify-content: center;
                padding: 2px;
                border-radius: 5px;
                background-color: #fff;
                border: 2px solid #000;
                height: 15px;
            }

            input:checked {
                border-color: #00c92a;
                background-size: cover;
                background: #00c92a url("../../../assets/confirm.png") 50% no-repeat;
            }

            // input:hover {
            //     border-color: #00c92a;
            //     background-size: cover;
            //     background: #00c92a url("../../assets/confirm.png") 50% no-repeat;
            // }
        }
    }
}

.ModalCadastroVendaAvulsa {

    // width: 100%;
    // height: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
    // background-color: rgba(#000, 0.6);
    text-align: center;
    font-size: 10px;

    h2 {
        font-size: 24px;
    }

    .relativePositionButton {
        position: relative;

        .ButtonClose {
            position: absolute;
            color: #000;
            //top: 5px;
            right: 8px;
            background-color: transparent;
            opacity: 1;
            border: none;
            outline: none;
            border-radius: 70%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999;

            // @media screen and (max-width: 768px) {
            //     bottom: 22.5rem;
            //     left: 11.4rem;
            // }

            // @media screen and (max-width: 430px) {
            //     bottom: 21.9rem;
            //     left: 7.9rem;
            // }

            svg {
                width: 20px;
            }
        }
    }

    .ModalWrapperTwo::-webkit-scrollbar {
        width: 12px;
    }

    .ModalWrapperTwo {
        // height: 80vh;
        // position: absolute;
        // width: 550px;
        // padding-bottom: 2rem;
        // max-height: 765px;
        // padding-top: 7rem;
        padding-bottom: 1rem;
        // overflow-y: auto;
        background: #ffffff;
        border: none;
        //box-sizing: border-box;
        border-radius: 20px;

        // @media screen and (max-width: 768px) {
        //     width: 420px;
        // }

        // @media screen and (max-height: 765px) {
        //     top: 0;
        // }

        // @media screen and (max-width: 430px) {
        //     width: 300px;
        // }

        .headerModal {
            display: flex;
            margin: 1rem 0;
            width: 100%;
            margin-top: 2rem;
            border-radius: 15px 15px 0px 0px;
            align-items: center;
            justify-content: center;
        }

        .CadInfo {
            color: #409900;
            line-height: 16px;

            .labelCad {
                font-weight: bold;
                color: #000;
                font-family: Sora;
            }
        }

        .CadInfo>h2 {
            font-weight: 700 !important;
            font-size: 22px !important;
            font-family: Sora !important;

            @media screen and (max-width: 430px) {
                font-size: 20px !important;
            }
        }

        .StepTracker {
            max-width: 275px;
            max-height: 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 1rem auto 20px auto;

            .Step1 {
                max-width: 80px;

                p {
                    font-family: Sora;
                    font-size: 10.5px;
                    color: black;
                    font-weight: bold;
                }

                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .Step2Gray {
                max-width: 80px;

                p {
                    font-family: Sora;
                    font-size: 10.5px;
                    color: black;
                    font-weight: 700 !important;
                }

                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .Step2Green {
                max-width: 80px;

                p {
                    font-family: Sora;
                    font-size: 10.5px;
                    color: black;
                    font-weight: 700 !important;
                }

                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .select-payment-type {
                &:focus {
                    box-shadow: 0 0 0 3px #85e !important;
                }
            }
        }

        .groupHeader {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;

            .formInput {
                height: 40px;
                padding: 8px;
                font-size: 16px;
                text-align: center;
                font-family: Sora;
                font-weight: 700;
                outline: none;
                width: 100%;
                border-radius: 7.5px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                border: 1.5px solid #000;
            }

            .formInput:focus {
                border: 2px solid #00c92a;
            }
        }

        .groupHeader>label {
            width: 100%;
            margin: 0;
            // margin-left: 10px;
            text-align: left !important;
        }

        .groupHeader>label>span {
            width: 100%;
            font-size: 12.5px;
            color: black;
            font-weight: 700;
            font-family: Sora !important;
            text-align: left !important;
        }

        .Salesform {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0 16px;

            label {
                font-weight: bold;
                font-size: 14px;
            }
        }

        .button {
            width: 100%;
            border-radius: 5px;
            border: none;
            user-select: none;
            text-transform: uppercase;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:disabled {
                opacity: 0.75;
                cursor: wait;
            }

            .progress {
                background: none;
                margin-left: 8px;
            }
        }

        .AlertKobe {
            margin-right: auto;
            margin-left: auto;
            display: flex;
            align-items: center;
            max-width: 290px;
            height: 60px;

            #strong {
                color: #00c92a;
            }

            p {
                font-size: 15px;
                font-family: Sora;
                font-weight: 700;
            }

            @media screen and (max-width: 768px) {
                height: 40px;
                margin: 40px 0;
            }
        }

        .buttonGroup {
            width: 100%;
            display: flex;
            margin-top: 10px;
            align-items: center;
            justify-content: center;

            .buttonConfirm {
                width: 80%;
                margin: 0 auto;
                height: 40px;
                color: black;
                font-family: Sora;
                font-weight: bold;
                background: #00c92a;
                box-shadow: 0px 13.4869px 20.2304px rgba(0, 0, 0, 0.14),
                    0px 5.05759px 25.288px rgba(0, 0, 0, 0.12),
                    0px 6.74346px 8.42932px rgba(0, 0, 0, 0.2);
                border: none;
                border-radius: 20px;
                border: 2px solid #00c92a;
                transition: 0.8s all;
                font-size: 1rem;

                &:hover {
                    background-color: #fff;
                    color: #00c92a;
                }

                // @media screen and (max-width: 768px) {
                //     width: 80%;
                // }
            }

            .buttonCancel {
                padding: 0.5rem 1rem;
                text-align: center;
                background: #fff;
                box-shadow: 0px 10.7429px 16.1143px rgba(0, 0, 0, 0.14),
                    0px 4.02857px 20.1429px rgba(0, 0, 0, 0.12),
                    0px 5.37143px 6.71429px rgba(0, 0, 0, 0.2);
                color: #000;
                border: none;
                border-radius: 20px;
                transition: all 0.2s ease-in-out;

                svg {
                    font-size: 20px;
                }
            }

            .buttonCancel:hover {
                transition: all 0.4s ease-in-out;
                background-color: #fff;
                color: #00c92a;
                transform: scale(1.01);
            }
        }

        p {
            font-size: 14px;
            font-weight: bold;
            margin: initial;
        }

        h1 {
            margin-top: 4px;
            font-weight: 800;
        }

        .progress {
            width: 36px;
            height: 36px;
        }

        .row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: center;
            margin-left: auto;
            gap: 5px;
            margin-right: auto;
            width: 100%;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        .group {
            width: 100%;
            margin-bottom: 20px;
            font-size: 14px;
            padding: 0;

            .total-cashback-message {
                margin-top: 4px;

                strong {
                    color: #00c92a;
                }
            }

            .groupCategoriesList {
                margin-top: 15px;
                gap: 5px;
                display: flex;
                text-align: left;
                flex-direction: column;
                max-height: 40px;
                overflow-y: auto;

                span {
                    font-size: 1.1em;
                    font-weight: 700;
                }
            }

            .boxArrowAdd {
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #00c92a;
                border-radius: 4px;
                cursor: pointer;
                transition: 0.6s all;

                &:hover {
                    transform: scale(1.1);
                }

                img {
                    width: 16px;
                }
            }

            .grupInputRadio {
                margin-top: 5px;
                display: flex;
                align-items: center;
                width: 100%;
                gap: 5px;

                label {
                    font-size: 14px;
                    margin: 0;
                    cursor: pointer;
                }

                input {
                    position: relative;
                    //width: 25px;
                    // -webkit-appearance: none;
                    border-radius: 50%;
                    //height: 25px;
                    display: flex;
                    align-items: center;
                    //border: 2px solid #000;

                    &::after {
                        content: "";
                        margin: 0 auto;
                        border-radius: 50%;
                        cursor: pointer;
                        width: 15px;
                        height: 15px;
                        background-color: transparent;
                        transition: 0.8s all;
                    }

                    &:hover::after {
                        background-color: #00c92a;
                    }

                    &:checked::after {
                        background-color: #00c92a;
                    }
                }
            }

            // @media screen and (max-width: 768px) {
            //     width: 300px;
            //     margin-bottom: 10px;
            // }

            // @media screen and (max-width: 430px) {
            //     width: 250px;
            //     margin-bottom: 10px;
            // }

            label {
                font-size: 0.75rem;
            }

            span {
                font-size: 0.75rem;
            }

            .formInput {
                height: 40px;
                padding: 8px;
                font-size: 16px;
                text-align: left;
                border-radius: 7.5px;
                border: 1px solid #c6c8c9;
            }

            .formInputValue {
                border-radius: 7.5px;
                border: 1.5px solid #000;
                padding: 8px;
                font-weight: 700;
                font-family: Sora;
                text-align: center;
                font-size: 16px;
                outline: none;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                width: 100%;

                // @media screen and (max-width: 768px) {
                //     width: 300px;
                // }

                // @media screen and (max-width: 430px) {
                //     width: 250px;
                // }
            }

            .formInputValue:focus {
                border: 2px solid #00c92a;
            }
        }

        .group>p {
            font-size: 14px !important;
            color: #000;
            text-align: left;
            // margin-left: 10px;
            font-weight: bold;
            font-family: Sora;
        }

        .clienteCard {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding: 0px 8px;
            // align-items: start;
            width: 340px;
            border: black 1px solid;
            border-radius: 10px;
            font-size: 14px;

            // @media screen and (max-width: 768px) {
            //     width: 320px;
            // }

            // @media screen and (max-width: 430px) {
            //     width: 250px;
            //     // height: 208px;
            // }

            .assinatura-wrapper {
                margin-top: 40px;
                width: 100%;
                border-top: 2px dashed black;
                display: none;
            }
        }

        .clienteInfo {
            color: #0acc0a;
            display: flex;
            flex-direction: column;
            padding: 24px 10px 10px;
            width: 100%;
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;

            label {
                font-family: Sora !important;
                font-weight: 700;
                font-size: 1.3em;
            }
        }

        .groupInforSale {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .boxInforSale {
                display: flex;
                width: 100%;
                align-items: start;
                flex-direction: column;

                label {
                    font-size: 1.2em;
                    color: #00c92a;
                    font-weight: 700;
                }

                span {
                    font-size: 1em;
                    font-weight: 600;
                }

                &.print-estabelecimento {
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 10px;

                    .storeName {
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                    }
                }
            }
        }

        .compraInfo {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 8px;

            .colEsq {
                display: flex;
                text-align: left;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;

                label {
                    font-family: Sora !important;
                    font-weight: 700;
                }
            }

            .colDir {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 50%;

                label {
                    font-family: Sora !important;
                    font-weight: 700;
                }
            }
        }

        .totalInfo {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 8px;

            .colEsq {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;

                label {
                    font-family: Sora !important;
                    font-weight: 700;
                }
            }

            .colDir {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 50%;
            }

            .cashback-value {
                font-family: Sora !important;
                font-weight: 700;
                color: #0acc0a;
            }
        }

        .senhaInput {
            margin-top: 10px;
            padding: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            justify-content: space-evenly;

            label {
                font-size: 13pt;
                font-family: Sora;
                color: #000;
                font-weight: 700;
                margin-left: 5px;
            }

            .inputShowPass {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                padding: 5px;
                border-radius: 8px;
                border: 1px solid black;

                input {
                    border: none;
                    outline: none;
                    width: 100%;
                    border-radius: 5px;
                    font-family: Sora;
                    padding: 5px;
                    font-weight: bold;
                }

                img {
                    cursor: pointer;
                }
            }
        }
    }
}

.description-textarea {
    margin-top: 5px;
    border-radius: 7.5px;
    border: 1.5px solid #000;
    padding: 10px;
    font-family: "Sora";
    padding: 10px;
    font-size: 14px;
    outline: none;
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.description-textarea:focus {
    border: 2px solid #00c92a;
}

.buttonGroupWithPrint {
    display: flex;
    flex-direction: column;
}

.buttonGroupWithPrint div {
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

@media print {
    .ModalCadastroVendaAvulsa {
        height: auto;
    }
}