.Term {
  width: 80%;
  margin: 0 auto;
  overflow: auto;
  text-align: justify;
  height: 400px;

  h2 {
    font-family: Sora;
    font-weight: 700;
    font-size: 1.6em;
  }

  h3 {
    font-family: Sora;
    font-weight: 700;
    font-size: 1.4em;
  }

  p {
    font-family: Sora;
    font-size: 1.2em !important;
    font-weight: 500;

    a {
      text-decoration: none;
      font-size: 1em !important;
      color: #00ee00;
    }
  }

  ol li {
    font-family: Sora;
    font-weight: 700;
  }
}

@media (max-width: 950px) {
  .Term {
    height: auto;

  }
}