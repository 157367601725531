@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");

.Body {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .Perfil {
        display: flex;
        margin: 0 auto 20px auto;
        justify-content: center;
        width: 97vw;
        max-width: 1400px;
        gap: 3vw;

        .left {
            box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
            width: 27%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: auto;
            border-radius: 20px;
            background-color: white;

            #aling {
                padding: 10%;
                display: flex;
                text-align: justify;
                flex-direction: column;

                #barra {
                    width: 40%;
                    background-color: #00ee00;
                    border: none;
                    height: 4px;
                    opacity: 1;
                }
            }

            #aling>h2 {
                margin: 0;
                width: 75%;
                font-family: Sora;
                color: #000;
                font-weight: bold;
            }

            #aling>h5 {
                margin: 0;
                font-weight: bold;
                font-family: Sora;
            }

            #vector {
                width: 20rem;
                //max-width: 105%;
                //min-height: 300px;
            }
        }

        .right {
            width: 73%;
            // height: 850px;
            padding: 20px;
            border-radius: 20px;

            .alingRight {
                display: flex;
                flex-direction: column;

                .containerOne {
                    display: flex;
                    margin-bottom: 10px;
                    align-items: center;
                    justify-content: space-between;

                    .boxPerfil {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-bottom: 3px solid #00ee00;
                        padding-bottom: 10px;
                        padding-left: 5px;
                        padding-right: 15px;
                    }

                    .boxPerfil>h2 {
                        font-family: Sora;
                        margin: 0;
                        color: #00ee00;
                        font-weight: bold;
                    }

                    .button {
                        padding: 10px;
                        width: 180px;
                        font-family: Sora;
                        font-weight: 600;
                        font-size: 18px;
                        color: white;
                        border: 2px solid #00ee00;
                        background: #00ee00;
                        border-radius: 40px;
                        transition: 0.8s all;
                    }

                    .button:hover {
                        background: #fff;
                        color: #00ee00;
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .Body {
        .Perfil {
            flex-direction: column;

            .left {
                width: 90%;
                margin: 0 auto;
                flex-direction: initial;
                height: 300px;

                #aling {
                    padding: 5%;
                    width: 60%;
                    display: flex;
                    text-align: justify;
                    flex-direction: column;

                    #barra {
                        width: 50%;
                        background-color: #00ee00;
                        border: none;
                        height: 4px;
                        opacity: 1;
                    }
                }

                #aling>h2 {
                    margin: 0;
                    width: 75%;
                    font-family: Sora;
                    color: #000;
                    font-weight: bold;
                }

                #aling>h5 {
                    margin: 0;
                    font-weight: bold;
                    font-family: Sora;
                }

                #vector {
                    max-width: auto;
                    min-height: 220px;
                    max-height: 220px;
                }
            }

            .right {
                width: 100%;
                height: auto;

                .alingRight {
                    .containerOne {
                        display: none;
                    }
                }
            }
        }
    }
}

.no-purchases-info {
    font-size: 1rem;
    text-align: center;
}

@media (max-width: 600px) {
    .Body {
        .Perfil {
            .left {
                height: 220px;

                #aling>h2 {
                    font-size: 4vw;
                }

                #aling>h5 {
                    font-size: 2.5vw;
                }

                #vector {
                    max-width: 200px;
                    min-height: auto;
                    max-height: auto;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .Body {
        .Perfil {
            .left {
                height: 180px;

                #aling>h2 {
                    font-size: 4vw;
                }

                #aling>h5 {
                    font-size: 3.5vw;
                }

                #vector {
                    max-width: 140px;
                    min-height: auto;
                    max-height: auto;
                }
            }
        }
    }
}