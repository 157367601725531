.mtp-7 {
    margin-top: 7rem;
}

.card {
    border-radius: 1rem !important;
    min-width: 100% !important;
}

@media screen and (max-width:550px) {
    .master-container {
        flex-direction: column;
    }
}