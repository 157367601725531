$Gradiente_Fundo_body: linear-gradient(90deg, #137a53 0%, #05fe9f 100%);
$Cor_Sombra: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.2);
$Cor_Fundo_Box_Left: #fff;
$Cor_Borda_Campos: #c6c8c9;
$Cor_Botao_Entrar: #009c60;
$Cor_Hover_Botao_Entrar: #009930;

.NewReceiptModal {
    position: fixed;
    z-index: 3000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(black, 0.6);
    padding: 30px 0;
    overflow-y: auto;

    .ToastModalContainer {
        width: 80%;
        margin: 0 auto;
        max-width: 500px;
        border-radius: 20px;
        padding: 15px;
        border: 4px solid #00ee00;
        background-color: #fff;
        text-align: center;

        .toastText {
            font-weight: 700;
            text-align: justify;
        }

        .btn {
            font-weight: 700;
            border: 2px solid #00ee00 !important;
            border-radius: 8px;
            transition: 0.7s all;

            &:hover {
                background-color: #fff !important;
                color: #00ee00 !important;
            }
        }
    }

    .FormWrapper {
        background-color: white;
        max-width: 90%;
        width: 500px;
        padding: 18px 16px;
        border-radius: 10px;
        position: relative;

        h1 {
            text-align: center;
            font-size: 2em;
            font-weight: 700;
            color: #409900;
        }

        h2 {
            font-size: 13px;
            text-align: center;
            font-weight: bold;
            font-size: 1em;
        }

        .creation-select {
            border: 3px solid #d2d2d2;
            background-color: #f5f5f5;
            font-weight: 700;
            outline: none;
            width: 100%;

            &:focus {
                box-shadow: none;
            }
        }

        .help-find-key {
            display: block;
            text-align: center;
            color: #17a2b8;
            cursor: pointer;
            margin-top: 10px;

            a {
                color: #000;
                font-weight: 700;
            }
        }

        .NewReceiptForm {
            margin-top: 10px;
            font-size: 14px;
            padding-top: 10px;

            .btn-submit {
                width: 70%;
                margin: 0 auto;
                padding: 10px 12px;
                background-color: #00ee00;
                color: #000;
                font-weight: 700;
                border: 1px solid #eee;
                border-radius: 20px;
                cursor: pointer;
                font-size: 1.4em;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;

                &:disabled {
                    opacity: 0.6;
                    cursor: wait;
                }
            }

            .btn-delete-photo {
                width: 70%;
                margin: 0 auto;
                padding: 8px 12px;
                background-color: red;
                color: #fff;
                font-weight: 700;
                border: 1px solid #eee;
                border-radius: 20px;
                cursor: pointer;
                font-size: 1.4em;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;

                &:disabled {
                    opacity: 0.6;
                }
            }

            .error-message {
                font-weight: 700;
                font-size: 0.8rem;
                color: red;
                text-align: left;
            }

            .qr-reader-container {
                max-width: 100%;
                margin: 10 auto;
                overflow: hidden;
            }

            .photo-upload-wrapper {
                input[type="file"] {
                    display: none;
                }
            }

            .upload-photo-label {
                background-color: #eeeeee;
                display: flex;
                align-items: center;
                gap: 10px;
                width: 70%;
                border-radius: 20px;
                margin: 0 auto;
                justify-content: center;
                padding: 8px 12px;
                color: #000;
                border: 1px solid #eee;
                cursor: pointer;
                font-size: 1.4em;

                svg {
                    font-size: 25px;
                }

                span {
                    padding-top: 3px;
                }
            }

            .receipt-preview {
                border-radius: 4px;
                background-size: cover;
                background-position: center center;
                height: 300px;
                max-width: 350px;
                overflow: hidden;
                margin: 0 auto;
                border: 2px dashed #ddd;
                margin-bottom: 10px;
            }
        }

        .closeButton {
            position: absolute;
            color: #212529;
            right: 10px;
            top: 10px;
            opacity: 1;
            border: none;
            outline: none;
            border-radius: 50%;
            padding: 12px;
            width: 35px;
            height: 35px;
            display: flex;
            background-color: #f5f5f5;
            align-items: center;
            justify-content: center;
        }

        label {
            margin-bottom: 4px;
            margin-left: 8px;
            font-size: 1.2em;
        }
    }

    .form-group {
        margin-bottom: 16px;

        select,
        input {
            border: 2px solid #000;
            outline: none;
            font-weight: 700;

            &:focus {
                background: #fff;
                border-color: transparent;
                box-shadow: 0 0 0 3px #00ee00;
            }

            &:valid {
                background: #fff;
                border-color: transparent;
                box-shadow: 0 0 0 3px #00ee00;
            }
        }

        .progress {
            background: none;
            margin-left: 8px;
        }

        &.qr-reader-container {
            border: 1px solid blue;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .btn-next {
        width: 100%;
        padding: 8px 12px;
        background: $Gradiente_Fundo_body;
        color: #fff;
        //box-shadow: $Cor_Sombra;
        border: 1px solid #eee;
        border-radius: 7px;
        cursor: pointer;
        font-size: 0.875rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;

        &:hover {
            background: $Cor_Hover_Botao_Entrar;
            box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
        }

        &:disabled {
            opacity: 0.7;
            cursor: wait;
        }
    }

    .btn-next-value {
        &:disabled {
            cursor: initial;
        }
    }

    .btn-previous {
        width: 100%;
        border: 1px solid #f8f9fa;
        border-radius: 7px;
        cursor: pointer;
        font-size: 0.875rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        font-weight: bold;

        &:hover {
            color: #212529;
            background-color: #e2e6ea;
            border-color: #dae0e5;
        }
    }
}

@media (max-width: 450px) {
    .NewReceiptModal {
        .ToastModalContainer {
            width: 95%;
        }
    }
}
