.ImageCropModal {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  flex-direction: column;

  .ReactCrop {
    //max-height: 90vh;
    //overflow-y: auto;
    max-width: 800px;
  }

  .btn-cancel {
    display: inline-block;
    padding: 6px 12px;
    background: #fff;
    font-size: 14px;
    color: #137a53;
    text-transform: uppercase;
    border-radius: 5px;
    border: none;
    margin-top: 8px;
    margin-left: 8px;
    transition: 0.2s;
  }

  .btn-crop {
    display: inline-block;
    padding: 6px 12px;
    background: #137a53;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
    border: none;
    margin-top: 8px;
    margin-left: 8px;
    transition: 0.2s;
  }
}
