.btn-atualiza-cpf {
    &:disabled {
        opacity: 0.5;
    }
}

span {
    &.error {
        color: rgb(220, 38, 38);
    }
}
