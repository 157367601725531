.ModalDeposito {
  position: fixed;
  z-index: 2000;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.6);

  .ModalWrapper {
    position: absolute;
    width: 550px;
    height: 580px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    background: #ffffff;
    border: none;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
      0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 768px) {
      width: 420px;
      height: 620px;
    }

    @media screen and (max-width: 430px) {
      width: 300px;
    }

    .headerModal {
      display: flex;
      width: 70%;
      margin-top: 10px;
      height: 114px;
      border-radius: 15px 15px 0px 0px;
      align-items: center;
      text-align: center;
      justify-content: center;

      @media screen and (max-width: 768px) {
        width: 80%;
      }

      .CadInfo {
        color: #409900;
        line-height: 16px;

        .labelCad {
          font-weight: bold;
          color: #000;
          font-family: Sora;

          @media screen and (max-width: 430px) {
            font-size: 13px !important;
          }
        }
      }

      .CadInfo>h2 {
        font-weight: 700 !important;
        font-size: 30px !important;
        font-family: Sora !important;

        @media screen and (max-width: 768px) {
          font-size: 22px !important;
        }

        @media screen and (max-width: 430px) {
          font-size: 19px !important;
        }
      }
    }
  }

  .DepositForm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 0 16px;

    //botao fechar x
    .closeButton {
      position: relative;
      color: #000;
      bottom: 100px;
      left: 15.3rem;
      background-color: transparent;
      opacity: 1;
      border: none;
      outline: none;
      border-radius: 70%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;

      @media screen and (max-width: 768px) {
        bottom: 100px;
        left: 11.3rem;
      }

      @media screen and (max-width: 430px) {
        left: 8rem;
      }

      svg {
        width: 20px;
      }
    }

    label {
      font-weight: bold;
    }
  }

  .groupForms {
    width: 70%;

    @media screen and (max-width: 768px) {
      width: 90%;
    }

    .valueInfo {
      width: 80%;
      padding: 26px;
      margin: 0 auto;
      margin-bottom: 32px;
      border-radius: 10px;
      border: 1px solid #000;
      color: #0ACC0A;
      background-color: #fff;
      font-family: Sora;
      text-align: center;

      @media screen and (max-width: 768px) {
        width: 90%;
      }

      label {
        font-size: 1em;
        font-weight: 700;
        margin: initial;
      }

      h2 {
        font-size: 2em;
        margin-top: 14px;
        font-weight: 800;
      }
    }

    .group {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 16px;
      width: 80%;
      margin: 0 auto;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      label {
        width: 100%;
      }

      span {
        margin-left: 10px;
        font-family: Sora;
        font-size: 13.5px;
        color: black;
      }

      .agroupInput {
        display: flex;
        align-items: center;
        padding: 2px 8px;
        border-radius: 10px;
        border: 1.5px solid black;

        img {
          cursor: pointer;
        }

        .formInput {
          width: 100%;
          height: 41px;
          font-family: Sora;
          font-weight: 700;
          border-radius: 7.54px;
          border: none;

          padding: 8px;
          font-size: 16px;
          text-align: left;
          outline: none;
        }
      }
    }

    #TextBotton {
      font-family: Sora;
      margin: 10px 0px;
      width: 100%;
      font-weight: 700;
      text-align: center;
      color: #000;
      font-size: 0.9em;
    }
  }

  .valueInfo {
    width: 100%;
    padding: 16px;
    margin-bottom: 32px;
    border-radius: 5px;
    background-color: #009c60;
    color: #fff;
    text-align: left;

    label {
      font-size: 14px;
      font-weight: 400;
      margin: initial;
    }

    h2 {
      margin-top: 4px;
      font-weight: 800;
    }
  }

  .button {
    width: 100%;
    border-radius: 5px;
    border: none;
    user-select: none;
    text-transform: uppercase;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.75;
      cursor: wait;
    }

    .progress {
      background: none;
      margin-left: 8px;
    }
  }

  .buttonGroup {
    width: 100%;
    margin-top: 32px;

    .buttonConfirm {
      width: 80%;
      margin: 0 auto;
      height: 40px;
      color: black;
      font-family: Sora;
      font-weight: bold;
      background: #00EE00;
      box-shadow: 0px 13.4869px 20.2304px rgba(0, 0, 0, 0.14),
        0px 5.05759px 25.288px rgba(0, 0, 0, 0.12),
        0px 6.74346px 8.42932px rgba(0, 0, 0, 0.2);
      border: none;
      border-radius: 20px;
      border: 2px solid #00EE00;
      transition: 0.8s all;

      &:hover {
        background-color: #fff;
        color: #00EE00;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .buttonCancel {
      width: 100%;
      background: #9c0000;
      box-shadow: 0px 10.7429px 16.1143px rgba(0, 0, 0, 0.14),
        0px 4.02857px 20.1429px rgba(0, 0, 0, 0.12),
        0px 5.37143px 6.71429px rgba(0, 0, 0, 0.2);
      color: #fff;
      border: none;
      margin-top: 8px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin: initial;
  }

  h1 {
    margin-top: 4px;
    font-weight: 800;
    font-size: 30px;
  }

  .progress {
    width: 36px;
    height: 36px;
  }

}

@media screen and (min-width: 768px) {
  .buttonGroup {
    display: flex;

    .buttonConfirm {
      order: 1;
      margin-left: 6px;
    }

    .buttonCancel {
      margin-top: initial;
    }
  }
}

@media screen and (min-width: 1024px) {
  .ModalWrapper {
    padding: 12px 0;
  }

  .DepositForm {
    padding: 0 12px;
  }

  .group {
    label {
      font-size: 0.875rem;
    }

    .formInput {
      font-size: 0.875rem;
      text-align: center;
    }
  }
}

.cupomImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 0.625rem;
    color: #979797;
  }
}