.EditCategoryModal {
    position: fixed;
    z-index: 2000;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, 0.6);
    padding: 1rem;

    .buttonConfirm {
        width: 100%;
        font-weight: bold;
        font-family: Sora;
        color: white;
        font-size: 18px;
        border-radius: 12px;
        background-color: #0acc0a;
        border: none;
        transition: 0.5s all;
        padding: 0.5rem;
        margin: 0;

        &:disabled {
            background-color: rgba($color: #0acc0a, $alpha: 0.5);
        }
    }

    .ModalWrapper {
        position: relative;
        width: 500px;
        padding: 1rem;
        padding-bottom: 10px;
        background: #ffffff;
        border: none;
        box-sizing: border-box;
        border-radius: 12px;
        box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
            0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);

        .headerModal {
            display: flex;
            width: 100%;
            // margin-top: 10px;
            border-radius: 12px;
            align-items: center;
            text-align: center;
            justify-content: center;

            .CadInfo {
                color: #409900;
                line-height: 16px;
                text-align: center;

                .labelCad {
                    font-weight: bold;
                    color: #000;
                    font-family: Sora;

                    @media screen and (max-width: 430px) {
                        font-size: 13px !important;
                    }
                }
            }

            .CadInfo > h2 {
                font-weight: 700 !important;
                font-size: 30px !important;
                font-family: Sora !important;

                @media screen and (max-width: 768px) {
                    font-size: 22px !important;
                }

                @media screen and (max-width: 430px) {
                    font-size: 19px !important;
                }
            }
        }

        //botao fechar x
        .closeButton {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            color: #000;
            background-color: transparent;
            opacity: 1;
            border: none;
            outline: none;
            border-radius: 70%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999;

            svg {
                width: 20px;
            }
        }
    }

    .EditCategoryForm {
        width: 100%;
        margin-top: 2rem;

        label {
            font-weight: bold;
        }
    }

    .groupForms {
        .valueInfo {
            width: 80%;
            padding: 26px;
            margin: 0 auto;
            margin-bottom: 32px;
            border-radius: 10px;
            border: 1px solid #000;
            color: #0acc0a;
            background-color: #fff;
            font-family: Sora;
            text-align: center;

            @media screen and (max-width: 768px) {
                width: 90%;
            }

            label {
                font-size: 1em;
                font-weight: 700;
                margin: initial;
            }

            h2 {
                font-size: 2em;
                margin-top: 14px;
                font-weight: 800;
            }
        }

        .group {
            margin-top: 16px;

            margin: 0 auto;

            label {
                width: 100%;
            }

            span {
                margin-left: 10px;
                font-family: Sora;
                font-size: 13.5px;
                color: black;
            }

            .agroupInput {
                display: flex;
                align-items: center;
                padding: 2px 8px;
                border-radius: 10px;
                border: 1.5px solid black;

                img {
                    cursor: pointer;
                }

                .formInput {
                    width: 100%;
                    height: 41px;
                    font-family: Sora;
                    font-weight: 700;
                    border-radius: 7.54px;
                    border: none;

                    padding: 8px;
                    font-size: 16px;
                    text-align: left;
                    outline: none;
                }
            }
        }

        #TextBotton {
            font-family: Sora;
            margin: 10px 0px;
            width: 100%;
            font-weight: 700;
            text-align: center;
            color: #000;
            font-size: 0.9em;
        }
    }

    .valueInfo {
        width: 100%;
        padding: 16px;
        margin-bottom: 32px;
        border-radius: 5px;
        background-color: #009c60;
        color: #fff;
        text-align: left;

        label {
            font-size: 14px;
            font-weight: 400;
            margin: initial;
        }

        h2 {
            margin-top: 4px;
            font-weight: 800;
        }
    }

    .button {
        width: 100%;
        border-radius: 5px;
        border: none;
        user-select: none;
        text-transform: uppercase;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
            opacity: 0.75;
            cursor: wait;
        }

        .progress {
            background: none;
            margin-left: 8px;
        }
    }

    .buttonGroup {
        width: 100%;
        margin-top: 0.5rem;

        .buttonCancel {
            width: 100%;
            background: #9c0000;
            box-shadow: 0px 10.7429px 16.1143px rgba(0, 0, 0, 0.14),
                0px 4.02857px 20.1429px rgba(0, 0, 0, 0.12),
                0px 5.37143px 6.71429px rgba(0, 0, 0, 0.2);
            color: #fff;
            border: none;
            margin-top: 8px;
        }
    }

    p {
        font-size: 14px;
        font-weight: 400;
        margin: initial;
    }

    h1 {
        margin-top: 4px;
        font-weight: 800;
        font-size: 30px;
    }

    .progress {
        width: 36px;
        height: 36px;
    }
}

@media screen and (min-width: 768px) {
    .buttonGroup {
        .buttonCancel {
            margin-top: initial;
        }
    }
}

@media screen and (min-width: 1024px) {
    .ModalWrapper {
        padding: 12px 0;
    }

    .group {
        label {
            font-size: 0.875rem;
        }

        .formInput {
            font-size: 0.875rem;
            text-align: center;
        }
    }
}

.cupomImg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
        font-size: 0.625rem;
        color: #979797;
    }
}
