@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300&display=swap');

footer {
    margin: 0;
    font-weight: bold;
    width: 100%;
    font-family: Sora;
    color: white;

    footer>h2,
    p,
    h3 {
        font-family: Sora;
        font-weight: bold;
        margin: 0;
    }

    #main_boxOne {
        width: 100%;
        height: 150px;
        background-color: transparent;
        display: flex;
        justify-content: center;

        .container_footer {
            width: 1080px;
            padding: 20px;
            height: 75px;
            background-color: transparent;
            display: flex;
            justify-content: space-around;

            .boxs_footer {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;
                height: 75px;

                .img_box {
                    display: flex;
                    height: 75px;
                    justify-content: flex-end;

                    .img_element {
                        width: 50px;
                        background-color: white;
                        color: #000000;
                        border-radius: 50%;
                        display: flex;
                        padding: 3px;
                        font-size: 30px;
                        justify-content: center;
                        align-items: center;
                        height: 50px;

                    }
                }

                .text_box {
                    display: flex;
                    height: 75px;
                    color: #000000;
                    flex-direction: column;
                    justify-content: flex-start;

                    .boxTexxx {
                        display: flex;
                        color: #000000;
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 10px;
                    }

                    .boxTexxx>p {
                        font-family: Sora;
                    }
                }

                .text_box>h2 {
                    font-family: Sora;
                    font-weight: bold;
                }
            }

            .box_rs {
                display: flex;
                height: 75px;
                gap: 5px;
                color: #000000;
                flex-direction: column;
                justify-content: flex-start;

                .iconsBox {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;

                    .iconsHover {
                        font-size: 28px;
                        color: #000000;
                        ;
                        cursor: pointer;
                        transition-duration: 0.8s;
                    }

                    .iconsHover:hover {
                        color: #11c76f;
                        transition-duration: 1s;
                    }
                }
            }

            .box_rs>h2 {
                font-family: Sora;
                font-weight: bold;
            }
        }

        .dN {
            display: none;
        }
    }

    #main_boxTwo {
        width: 100%;
        display: flex;
        height: 350px;
        background-image: linear-gradient(#0ACC0A, #0ACC0A);
        border-bottom: 2px solid white;
        justify-content: center;
        border-top: 2px solid white;

        .container_footerTwo {
            display: flex;
            justify-content: center;
            width: 1080px;
            padding: 20px;
            height: 350px;
            background-color: transparent;
            display: flex;
            justify-content: space-between;

            .boxAlingOne {
                width: 30%;
                font-family: Sora;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px;
                flex-direction: column;

                #textPrinc {
                    width: 90%;
                    text-align: center;
                    font-family: Sora;
                    letter-spacing: 2px;
                }
            }


            .boxAlingTwo {
                width: 40%;
                padding: 5px;
                display: flex;
                font-family: Sora;
                border-left: 2px solid white;
                border-right: 2px solid white;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                .textAling {
                    display: flex;
                    flex-direction: column;
                    height: 70%;
                    gap: 5px;
                    justify-content: space-between;
                }

                .textAlingc {
                    text-align: center;
                    width: 100%;
                }

            }

            .boxAlingOne {
                .textAling {
                    display: flex;
                    flex-direction: column;
                    height: 70%;
                    text-align: center;
                    gap: 5px;
                    justify-content: space-between;
                }

                .w_100 {
                    width: 100%;
                }
            }
        }
    }

    #main_boxThree {
        width: 100%;
        background-color: #0ACC0A;
        display: flex;
        height: 80px;
        justify-content: center;

        .container_footerThree {
            width: 1080px;
            padding: 20px;
            height: 80px;
            background-color: transparent;
            display: flex;
            justify-content: space-around;

            .boxs_footerThree {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                text-align: center;

                #logoL {
                    width: 75px;
                }
            }

            .w_25 {
                width: 25%;
            }

            .boxs_footerThree {
                .box_textDireitos {
                    display: flex;
                    width: 100%;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                }

                .box_textDireitos>h2 {
                    font-size: 15px;
                    margin: 0;
                    font-weight: bold;
                    font-family: Sora;
                }
            }

            .boxs_footerThree {

                .box_textTrhee {
                    display: flex;
                    height: 25px;
                    padding: 3px;
                    align-items: center;
                    justify-content: center;
                }

                .box_textTrhee>p {
                    font-size: 14px;
                    font-family: Sora;
                }
            }

        }
    }
}

@media (max-width: 860px) {
    footer {
        #main_boxOne {
            height: 120px;

            .container_footer {
                height: 110px;

                .boxs_footer {
                    gap: 15px;
                    height: 60px;

                    .img_box {
                        height: 55px;

                        .img_element {
                            width: 30px;
                            height: 30px;
                            font-size: 20px;
                        }
                    }

                    .text_box {
                        height: 55px;
                    }

                    .text_box>div {
                        font-size: 12px;
                    }

                    .text_box>h2 {
                        font-size: 20px;

                    }

                    .box_rs {
                        height: 55px;
                    }

                    .box_rs>h2 {
                        font-size: 20px;
                    }
                }
            }
        }

        #main_boxTwo {
            .container_footerTwo {
                .boxAlingOne {
                    text-align: center;

                    #textPrinc {
                        font-size: 12.5px;
                    }

                    .textAling {
                        font-size: 13px;
                    }
                }

                .boxAlingTwo {
                    .textAling {
                        font-size: 13px;
                    }
                }
            }
        }

        #main_boxThree {
            .container_footerThree {
                .boxs_footerThree {
                    .box_textDireitos>h2 {
                        font-size: 12px;
                    }

                    .box_textTrhee>p {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}

@media (max-width: 660px) {
    footer {
        #main_boxOne {
            height: 100px;

            .container_footer {
                height: 90px;

                .boxs_footer {

                    .text_box>h2 {
                        font-size: 12px;
                    }

                    .text_box>div>p {
                        font-weight: bold;
                        font-size: 1.8vw;
                    }

                    .box_rs {
                        height: 55px;
                    }

                    .box_rs>h2 {
                        font-size: 1.8vw;
                    }

                    .box_rs {
                        .iconsBox {
                            .iconsHover {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        #main_boxTwo {
            height: 600px;

            .container_footerTwo {
                height: 600px;
                flex-direction: column;

                .boxAlingOne {
                    width: 100%;
                    border-left: 2px solid white;
                    border-right: 2px solid white;

                    #textPrinc {
                        font-size: 12px;
                    }

                    .textAling>p {
                        font-size: 12px;
                    }
                }

                .boxAlingOne>h3 {
                    font-size: 18px;
                }

                .boxAlingTwo {
                    width: 100%;

                    .textAlingc {
                        font-size: 18px;
                    }

                    .textAling>p {
                        font-size: 12px;
                    }
                }
            }
        }

        #main_boxThree {
            .container_footerThree {
                .boxs_footerThree {
                    .box_textDireitos>h2 {
                        font-size: 2.5vw;
                    }

                    .box_textTrhee>p {
                        font-size: 8.5px;
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    footer {
        #main_boxOne {
            height: 200px;

            .container_footer {
                flex-direction: column;
                height: auto;

                .groupBoxFooter {
                    display: flex;

                    .boxs_footer {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 15px;
                        height: 55px;

                        .img_box {
                            display: flex;
                            height: 75px;
                            justify-content: flex-end;

                            .img_element {
                                display: flex;
                                padding: 3px;
                                justify-content: center;
                                align-items: center;
                                width: 30px;
                                height: 30px;
                                font-size: 20px;
                            }
                        }

                        .text_box {
                            display: flex;
                            height: 75px;
                            gap: 5px;
                            width: 100px;
                            font-size: 12px;
                            flex-direction: column;
                            justify-content: flex-start;

                            .boxTexxx {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                gap: 10px;
                            }

                        }

                        .text_box>div>p {
                            font-family: Sora;
                            font-size: 12px;
                        }

                        .text_box>h2 {
                            font-size: 20px;
                        }
                    }
                }

                .boxs_footer {
                    width: 100%;
                    height: 90px;

                    .text_box {
                        width: 100px;

                        .text_box>div>p {
                            font-size: 13px;
                        }
                    }

                    .text_box>h2 {
                        font-size: 20px;
                    }

                    .box_rs {
                        width: 100px;
                    }

                    .box_rs>h2 {
                        font-size: 20px;
                    }
                }
            }

            .dN {
                display: flex;
            }

            .dA {
                display: none;
            }
        }

        #main_boxTwo {
            height: 610px;

            .container_footerTwo {
                height: 610px;

                .boxAlingOne {
                    .textAling>p {
                        font-size: 14px;
                    }
                }

                .boxAlingTwo {
                    .textAling {
                        font-size: 14px;
                    }
                }

            }
        }

        #main_boxThree {
            height: 130px;

            .container_footerThree {
                flex-direction: column;
                height: 130px;
                gap: 15px;

                .boxs_footerThree {
                    .box_textDireitos>h2 {
                        font-size: 13px;
                    }

                    .box_textTrhee>p {
                        font-size: 11.5px;
                    }
                }

                .w_25 {
                    width: 100%;
                }

                .w_50 {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 370px) {
    #main_boxOne {
        display: none !important;
    }
}