.SalesByCategoryListContainer {
    margin-top: 5em;
    width: 100%;
    height: 110vh;
    background-color: #f1f1f1;

    .Pagination {
        background-color: #f1f1f1;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    .salesTable {
        padding-bottom: 20px;
    }
}
