$Gradiente_Fundo_body: linear-gradient(90deg, #137a53 0%, #05fe9f 100%);
$Cor_Sombra: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.2);
$Cor_Fundo_Box_Left: #fff;
$Cor_Borda_Campos: #c6c8c9;
$Cor_Botao_Entrar: #009c60;
$Cor_Hover_Botao_Entrar: #009930;

.NewReceiptInfo {
    background-color: #fff;
    min-height: 100vh;
    padding: 2rem 0;
    overflow: hidden;

    .container {
        max-width: 960px;
        margin: 0 auto;
        background-color: #fff;
    }

    .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        padding: 1rem;
    }

    .NewReceiptInfo-card {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        text-decoration: none;
        color: #222;
        display: flex;
        align-items: center;
        gap: 1rem;
        flex: 1;
        font-size: 1.2rem;
        // justify-content: flex-end;

        .card-icon {
            padding: 1rem;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            color: #fff;
        }

        .view-receipts-icon {
            background-color: #11c76f;
        }

        .new-receipt-icon {
            background-color: purple;
        }

        p {
            margin: 0;
        }
    }

    .btn-send-receipt {
        background-color: purple;
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: center;
        padding: 8px 12px;
        color: #fff;
        border: 1px solid #eee;
        border-radius: 7px;
        cursor: pointer;
        font-size: 0.875rem;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    .btn-view-receipts {
        background-color: #11c76f;
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: center;
        padding: 8px 12px;
        color: #fff;
        border: 1px solid #eee;
        border-radius: 7px;
        cursor: pointer;
        font-size: 0.875rem;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    .faq-wrapper {
        margin-top: 2.5rem;

        > p {
            text-align: center;
            color: #11c76f;
            font-weight: bold;
            margin-top: 2rem;
            font-size: 0.875rem;
        }
    }

    .faq-list {
        padding: 0;
    }

    .faq {
        margin-top: 2rem;

        h5 {
            background-color: #11c76f;
            color: #fff;
            padding: 0.5rem;
            font-size: 1.06rem;
            margin: 0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        p {
            padding: 1rem;
            margin: 0;
            font-size: 0.9375rem;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 4px;
        }

        ul {
            padding: 0.5rem 0.5rem 0.5rem 2rem;
        }

        li {
            margin-bottom: 1rem;
            font-size: 0.9375rem;
            font-style: italic;
            font-weight: bold;
        }
    }

    .btn-go-back {
        text-decoration: none;
        display: flex;
        align-items: center;

        svg {
            font-size: 1rem;
            width: 14px;
        }
    }
}

@media screen and (max-width: 768px) {
    .NewReceiptInfo {
        .buttons-wrapper {
            flex-direction: column;
            gap: 0.5rem;
            align-items: flex-start;
            padding: 1rem 0;
        }

        .NewReceiptInfo-card {
            width: 100%;
        }
    }
}
