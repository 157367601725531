.chatBotContainer {
    z-index: 10;
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;

    .chatBanner {
        cursor: pointer;
        background-color: #cbfccb;
        padding: 0.5rem;
        display: flex;
        gap: 0.5rem;
        min-height: auto !important;
        align-items: center;
        border-radius: 2rem;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.055);

        .description {
            font-weight: 700 !important;
            margin: 0;
            padding: 7px;
        }
    }

    .chatContainer {
        background-color: #fff;
        max-width: 28rem;
        height: 90vh;
        margin-top: 20%;

        .chatHeader {
            background-color: var(--secondary-green);
            color: #fff;
            display: flex;
            justify-content: space-between;

            .headerContent {
                h4 {
                    font-size: 1rem;
                }

                span {
                    font-size: 0.8rem;
                }
            }

            .close {
                font-size: 2rem;
                color: #fff;
                cursor: pointer;
            }
        }

        .chatSection {
            max-height: 80%;
            overflow-y: auto;
            overflow-x: hidden;
            scroll-behavior: smooth;
            height: calc(100% - 170px);

            .messages {
                margin: 10px 0 10px 0;
                display: flex;
                flex-direction: column;
            }
        }

        .chatAction {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: #fff;

            .send {
                background-color: #fff;
                color: var(--green);
                border: none;

                span {
                    font-size: 2rem;
                }

                &:disabled {
                    cursor: wait;
                }
            }

            textarea {
                padding: 10px 12px;
                font-size: 14px;
            }
        }
    }
}


@media screen and (max-width: 700px) {
    .chatBotContainer {
        .chatBanner {
            .description {
                display: none;
            }
        }
    }
}