.withTable {
    max-width: 100%;
    margin: 0 auto;

    .CellHead {
      text-transform: uppercase;
      font-weight: bold;
      color: #444;
      cursor: pointer;

      span {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 12px;
          height: 12px;
          margin-left: 4px;

          &.desc {
            transform: rotate(180deg);
          }

          &.asc {
            transform: rotate(0);
          }
        }
      }
    }

    .TableTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;

      img {
        display: inline-block;
        width: 32px;
      }
    }

    .TableHitoryWith {
      border-radius: 10px;

      .TableRowHead {
        background: linear-gradient(90deg, #137a53 0%, #05fe9f 100%);
        background: #05fe9f;
        background-color: white;
      }

      .SellHead {
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        color: #444;
      }
    }

    .btn-see-more {
      display: inline-block;
      padding: 6px 12px;
      background: #05fe9f;
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      border-radius: 5px;
      border: none;
      margin-top: 8px;
      transition: 0.2s;

      &:hover {
        background: #05ed9f;
        color: #fff;
      }
    }
  }

  @media screen and (min-width: 494px) {
    .withTable {
      width: 80vw;
      .TableTitle {
        img {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 742px) {
    .withTable {
      width: 80vw;
      .main-content {
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .withTable{
      span{
        font-size: 15px;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 85vw;
      width: 65vw;
      .btn-see-more {
        padding: 12px;
      }
    }
  }

  @media screen and (max-width: 849px) {
    .withTable{
      width: 80vw;
    }
  }

