$Cor_Sombra:
    0px 2px 3px rgba(0, 0, 0, 0.14),
    0px 3px 6px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.2);

.form-group {
    label {
        width: 90%;
        font-weight: bold;
        color: #000;
        text-align: left !important;
    }
}

.MuiPaper-rounded {
    padding: 1rem;
}

.campo {
    &:focus {
        background: #fff;
        box-shadow: 0 0 0 3px #60089f;
    }
}

.response-message {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    padding: 0.5rem;
    text-align: right;
    color: black;
    margin-bottom: 1rem;

    h2 {
        font-family: Sora;
        font-weight: bold;
    }

    p {
        width: 90%;
        font-family: Sora;

        span {
            font-weight: bold;
        }
    }
}

.input-email {
    margin-bottom: 0.5rem;
}

.btn-reset-password,
.btn-close-dialog {
    width: 80%;
    background-color: #00ee00 !important;
    color: #000 !important;
    margin: 0 auto;
    padding: 8px 12px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.1rem;
    font-family: Sora !important;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-reset-password {
    background: #60089f;
    box-shadow: $Cor_Sombra;
    color: #fff;
    margin-bottom: 1rem;

    &:disabled {
        cursor: wait;
        opacity: 0.6;
    }
}

.btn-close-dialog {
    background-color: #ddd;
}

.error-message {
    align-self: flex-start !important;
    font-size: 1rem;
    color: #f44336;
    margin-left: 0;
}

.content {
    text-align: center;
    margin: 35px 0;

    h6 {
        font-family: Sora !important;
        font-size: 2em;
        font-weight: 700;
    }
}

@media (max-width: 950px) {
    .Dnone {
        display: none;
    }
}

@media (max-width: 950px) {
    .response-message {
        p {
            width: 90%;
            font-size: 0.9em !important;
        }
    }
}
