.agroupHistoric {
    display: flex;
    gap: 5%;
    margin-top: 20px;

    .boxLeft {
        width: 70%;
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 1rem;
        //justify-content: space-around;

        .containerOne {
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            justify-content: space-between;

            .boxPerfil {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-bottom: 3px solid #00ee00;
                padding-bottom: 10px;
                padding-left: 5px;
                padding-right: 15px;
            }

            .boxPerfil>h2 {
                font-family: Sora;
                margin: 0;
                color: #00ee00;
                font-weight: bold;
            }

            .button {
                padding: 10px;
                width: 180px;
                font-family: Sora;
                font-weight: 600;
                font-size: 18px;
                color: white;
                border: 2px solid #00ee00;
                background: #00ee00;
                border-radius: 40px;
                transition: 0.8s all;
            }

            .button:hover {
                background: #fff;
                color: #00ee00;
            }
        }

        .dNone {
            display: none !important;
        }

        .boxOnee {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .efect {
                text-align: start;
                display: flex;
                width: 100%;
                //justify-items: center;
                flex-direction: column;

                #barrinha {
                    width: 100%;
                    height: 4px;
                    opacity: 1;
                    background: linear-gradient(to right, #00ee00 65%, #fff 0);
                }
            }

            .efect>h3 {
                color: #00ee00;
                font-size: 1.4rem;
                margin: 0;
                font-family: Sora;
                font-weight: bold;
            }

            .groupData {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .form-control {
                    border: none;
                }

                #data {
                    font-family: Sora;
                    color: #A1A4AC;
                    font-weight: bold;
                    margin: 0;
                    text-align: center;
                    font-size: 1.2vw;
                }
            }

            #set {
                cursor: pointer;
            }
        }

        .historyBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            // box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
            background-color: #fff;
            padding: 25px;
            border-radius: 20px;

            .contHistory {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 25px;
            }

            .contHistory>h3 {
                font-family: Sora;
                font-weight: bold;
                font-size: 1em;
                margin: 0;
            }
        }

        .historyBox>p {
            font-family: Sora;
            margin: 0;
            font-size: 1em;
            color: #A1A4AC;
        }
    }

    .boxRight {
        width: 40%;
        display: flex;
        //justify-content: space-between;
        flex-direction: column;
        gap: 1rem;

        .boxApont {
            width: 100%;
            height: 100px;
            border-radius: 15px;
            display: flex;
            box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
            align-items: center;
            background-color: #fff;
            justify-content: flex-start;
            gap: 20px;

            .ImageeCircle {
                margin-left: 7%;
                display: flex;
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ECECEC;
                border-radius: 50%;
                align-items: center;
            }

            .groupArticle {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
            }

            .groupArticle>h3 {
                font-weight: bold;
                font-family: Sora;
                margin: 0;
                font-size: 1.1em;
            }

            .groupArticle>p {
                font-family: Sora;
                color: #313131;
                margin: 0;
                font-size: 0.8em;
            }
        }
    }
}

@media (max-width: 1100px) {
    .agroupHistoric {
        flex-direction: column;
        gap: 10px;

        .boxLeft {
            width: 100%;
            order: 2;

            .boxOnee {
                .efect>h3 {
                    font-size: 3vw;
                    margin: 0;
                    font-family: Sora;
                    font-weight: bold;
                }
            }

            .dNone {
                display: flex !important;
            }

            .historyBox {
                margin-bottom: 15px;
            }

            .groupData {
                gap: 5px;

                #data {
                    font-size: 2.2vw !important;
                }
            }

            .groupData>img {
                height: 40px;
            }
        }

        .boxRight {
            width: 100%;
            flex-wrap: wrap;
            gap: 30px;
            justify-content: center;
            margin-bottom: 50px;
            flex-direction: initial;

            .boxApont {
                width: 40vw;
            }
        }
    }
}

@media (max-width: 600px) {
    .agroupHistoric {
        .boxLeft {
            .containerOne {
                .button {
                    padding: 10px;
                    width: 120px !important;
                    font-family: Sora;
                    font-weight: 600;
                    font-size: 18px;
                    color: white;
                    border: 2px solid #00ee00;
                    background: #00ee00;
                    border-radius: 40px;
                    transition: 0.8s all;
                }
            }
        }

        .boxRight {
            flex-wrap: nowrap;
            gap: 30px;
            margin-bottom: 50px;
            flex-direction: column;

            .boxApont {
                width: 100%;

                .groupArticle>h3 {
                    font-size: 1.5em;
                }

                .groupArticle>p {
                    font-size: 1.2em;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .agroupHistoric {
        .boxLeft {
            .boxOnee {

                .efect {

                    #barrinha {
                        width: 100%;
                        height: 4px;
                        opacity: 1;
                        background: linear-gradient(to right, #00ee00 65%, #fff 0);
                    }
                }

                .efect>h3 {
                    color: #00ee00;
                    font-size: 1em;
                    margin: 0;
                    font-family: Sora;
                    font-weight: bold;
                }

                .groupData {

                    #data {
                        text-align: center;
                        font-size: 0.7em !important;
                    }
                }

                .groupData>img {
                    height: 30px;
                }
            }

            .historyBox {
                .contHistory>h3 {
                    font-size: 4vw;
                }
            }

            .historyBox>p {
                font-weight: bold;
                font-size: 3vw;
            }
        }

        .boxRight {
            .boxApont {
                width: 100%;

                .groupArticle>h3 {
                    font-size: 5vw;
                }

                .groupArticle>p {
                    font-size: 3.5vw;
                }
            }
        }
    }
}