@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@700&display=swap");

:root {
    --green: #00ee00;
    --secondary-green: #13811b;
    --gray: #e0dcdc;
}

* {
    font-family: Sora;
}

.mainContentController {
    height: 1rem;
}

body {
    background-color: #f8f8f9;
    overflow-x: hidden;
}

@media print {
    footer,
    header,
    main,
    .headerModal,
    .StepTracker,
    .ButtonClose,
    .buttonGroup,
    .chatBotContainer,
    .grid-container,
    .rodape {
        display: none !important;
    }

    .containerDash {
        height: 50vh !important;
        overflow: hidden !important;
    }

    .ModalCadastro {
        background-color: #fff !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }

    .ModalWrapperTwo {
        width: 900px !important;
    }

    .clienteCard {
        width: 800px !important;
        padding: 50px 20px !important;
    }

    .assinatura-wrapper {
        display: block !important;
    }

    .categorias-wrapper {
        height: 110px !important;
    }

    .print-estabelecimento img {
        width: 124px !important;
    }

    @page {
        margin: 2cm !important;
    }
}
