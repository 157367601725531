.typingMessage {
    position: relative;
    display: inline-block;
    background-color: var(--secondary-green);
    // background-color: red;
    width: 8rem;
    border-radius: 1.5rem;
    color: #fff;
    animation: typing 1.5s infinite;
    font-size: 0.812rem;
}

@keyframes typing {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    50% {
        transform: translateY(1rem);
        opacity: 0.9;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
