.polarGraphContainer {
    max-width: 250px !important;
    min-width: 250px !important;
    min-height: 250px;
    max-height: 250px;

    div {
        display: flex;
        justify-content: center;
    }
}