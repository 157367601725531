.containerText {
    height: 400px;
    overflow: auto;

    .groupTextModal {
        text-align: left;

        h2 {
            font-size: 1.4em;
            font-weight: 700;
        }

        p {
            font-weight: 500 !important;
            font-size: 1em;
            text-align: justify;
        }

        ul {
            li {
                font-weight: 700;
                font-size: 1.1em;
            }
        }
    }
}

@media (max-width: 950px) {
    .containerText {
        height: 100%;
    }
}