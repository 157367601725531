.cardHowWorks {
    box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.11);
    padding: 1em 1.4em;
    width: 25%;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;

    img {
        height: 150px;
    }
}


@media screen and (max-width: 1000px) {
    .cardHowWorks {
        width: 90%;
        max-width: 400px;
        margin: auto;
        justify-content: center;

        img {
            margin: auto;
        }
    }
}