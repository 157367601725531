.StoresScreen {
    background-color: #f8f8f8;
    position: relative;

    .containerMenuEList {
        flex-direction: column;
        align-items: initial;
    }

    .main-content {
        min-height: 100vh;
        max-width: 1300px;
        margin: 0 auto;
        padding: 0px 1rem 40px;
    }

    .filter {
        width: 100%;
        border: 4px solid #f5f5f5;
        padding: 6px 10px;
        font-size: 16px;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #e9e9e9 url("../../assets/arrowBottom.png") 95.5% 50% no-repeat;
        cursor: pointer;
        background-color: #f5f5f5;
        border-radius: 10px;
        outline: none;
        transition: 0.6s all;

        &:hover {
            background-color: #fff;
        }
    }

    .clear-filter {
        margin-top: 0.5rem;
        cursor: pointer;
        color: #007bff;
    }

    .no-stores {
        margin-top: 1rem;
        text-align: center;
        min-height: 40vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;

        p {
            font-size: 18px;
            word-break: keep-all;
        }

        .no-store-message {
            &::after {
                content: ":(";
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-left: 2px;
            }
        }
    }

    .inputSearch {
        background-color: #fff;
        justify-content: space-between;
        padding: 10px 20px;
        border-radius: 10px;
        align-items: center;
        display: flex;

        .leftInput {
            width: 100%;
            height: 100%;

            form {
                display: flex;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;
            }

            input {
                font-weight: 500;
                border: none;
                margin-top: 2.5px;
                outline: none;
                width: 80%;
            }
        }

        .buttonRight {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #00ee00;
            padding: 0;
            width: 60px;
            border: 3px solid #00ee00;
            border-radius: 10px;
            height: 50px;
            transition: 0.7s all;

            &:hover {
                background-color: transparent;
            }

            img {
                height: 60%;
            }
        }
    }

    .effectModal {
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        height: 100%;
    }

    .modalFilter {
        position: fixed;
        top: 0;
        right: -100vw;
        max-height: 100%;
        overflow: auto;
        min-width: 380px;
        z-index: 100000;
        transition: 0.7s all;

        background-color: #fff;

        .wrapper {
            width: 100%;
            display: flex;
            gap: 15px;
            flex-direction: column;

            .headerModal {
                margin-top: 20px;
                padding: 15px 18px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    color: #00ee00;
                    margin: 0;
                    font-weight: 600;
                    font-size: 1.1em;
                }

                img {
                    height: 15px;
                    transition: 0.7s all;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            h2 {
                font-weight: 600;
                margin: 0;
                padding: 0px 18px;
            }

            .FormControl {
                background-color: #fff;
                width: 100%;
                border: none;

                .MuiSelect-select:focus {
                    background-color: initial;
                }

                .MuiInput-underline:before,
                .MuiInput-underline:after {
                    border-bottom: none;
                }
            }

            h5 {
                font-size: 1rem;
            }
        }
    }

    .select-wrapper:last-child {
        margin-top: 1rem;
        padding: 0px 10px;
    }
}

@media screen and (min-width: 742px) {
    .StoresScreen {
        .containerMenuEList {
            flex-direction: row;
            align-items: flex-start;
        }

        .main-content {
            padding-top: 1rem;
        }
    }
}

.StoresScreen {
    .wrapper {
        display: flex;
    }

    .select-wrapper {
        width: 100%;

        .segmentsBox {
            display: flex;
            justify-content: space-between;
            padding: 20px 10px;
            border-bottom: 2px solid #ececec;

            label {
                cursor: pointer;
                font-size: 1.2em;
                color: #000;
                font-weight: 600;
            }

            input {
                all: unset;
                width: 15px;
                display: flex;
                justify-content: center;
                padding: 2px;
                border-radius: 5px;
                background-color: #fff;
                border: 2px solid #000;
                height: 15px;
            }

            input:checked {
                border-color: #00ee00;
                background-size: cover;
                background: #00ee00 url("../../assets/confirm.png") 50% no-repeat;
            }
        }
    }

    .select-wrapper:last-child {
        margin-top: 0;
    }

    .clear-filter {
        text-align: right;
    }
}

@media (max-width: 500px) {
    .StoresScreen {
        .modalFilter {
            right: -500px;
            min-width: 100%;
        }
    }
}