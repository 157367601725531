.modal {
    backdrop-filter: blur(20px);
    display: flex !important;
    justify-content: center;
    align-items: center;

    .modal-dialog {
        max-width: 1200px !important;
        width: 80% !important;
        max-width: none;

        .modal-content {
            border-radius: 20px;
            border: none;
            height: 550px;
            width: 100% !important;

            .modal-header {
                border: none;

                h2 {
                    font-family: Sora;
                    font-size: 1.4em;
                    font-weight: 700;
                    margin: 0;
                }
            }

            .modal-body {
                justify-content: center;
                text-align: center;
                align-items: center;

                p {
                    font-size: 1.2em;
                    font-weight: 700;
                }

                #none {
                    display: none;
                    font-family: Sora;
                    font-weight: 700;
                    color: #000;
                }

                .modal-left {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 70%;
                        max-height: 500px;
                    }
                }

                .modal-right {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }

                .w100 {
                    width: 100% !important;
                }
            }

            .modal-footer {
                border: none;
            }
        }
    }
}

@media (max-width: 950px) {
    .modal {
        min-height: 100vh;
        background-color: white;

        .modal-dialog {
            width: 100% !important;
            min-height: 100% !important;

            .modal-content {
                min-height: 100% !important;
                height: 100vh !important;
                width: 100% !important;

                .modal-body {
                    flex-direction: column;

                    #none {
                        display: flex;
                    }

                    .modal-left {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            max-width: 450px;
                            width: 60%;
                        }
                    }

                    .modal-right {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    }
                }

                .modal-footer {
                    border: none;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-left {
                        img {
                            width: 300px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 320px) {
    .modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-left {
                        img {
                            width: 250px;
                        }
                    }
                }
            }
        }
    }
}