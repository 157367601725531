.message-info-withdrawal {
  background-color: #fff;
  width: 80%;
  max-width: 400px;
  padding: 18px 16px;
  border-radius: 10px;
  position: relative;
  text-align: center;

  .check-icon {
    color: #009c60;
    width: 3rem;
    height: 3rem;
    margin-bottom: 2rem;
  }

  .success-message {
    color: #009c60;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .notification-contact-message {
    font-size: 14px;
  }

  .form-group {
    &:nth-child(1) {
      background-color: red;
    }
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

  .btn-ok {
    width: 100%;
    padding: 8px 12px;
    background: linear-gradient(90deg, #137a53, #05fe9f);
    color: #fff;
    border: 1px solid #eee;
    border-radius: 7px;
    cursor: pointer;
    font-size: 0.875rem;
    text-transform: uppercase;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1024px) {
  .message-info-withdrawal {
    max-width: 30%;
  }
}
