.CreateAvatar {
  //   .ReactCrop {
  //     width: 300px;
  //     height: 300px;
  //   }

  .btn-upload {
    display: block;
    max-width: 400px;
    padding: 6px 12px;
    background: #137a53;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
    border: none;
    margin-top: 8px;
    margin-left: 8px;
    transition: 0.2s;
    cursor: pointer;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
