.campo {
    width: 100%;
    height: 41px;
    border: 1px solid #c6c8c9;
    border-radius: 7.5px;
    padding: 8px;
    font-size: 16px;
    outline: none;
    &:focus {
        background: #fff;
        box-shadow: 0 0 0 3px #00ee00;
    }
}
