.containerInfor {
    width: 100%;

    .subcontainer {
        display: flex;
        flex-direction: column;

        .agroupboxInfor {
            display: flex;
            margin-top: 20px;
            align-items: center;
            justify-content: space-between;

            .inforCentro {
                min-width: 33.5%;
                max-width: 33.5%;
                padding: 0.5%;

                .boxInfor {
                    display: flex;
                    background-color: white;
                    align-items: center;
                    width: 100%;
                    padding: 8px;
                    border-radius: 10px;
                    height: 65px;
                    justify-content: space-between;

                    .d-flex {
                        gap: 8px;
                        max-width: 100%;
                        overflow: auto;
                        align-items: center;

                        .infor {
                            font-family: Sora;
                            font-weight: 700 !important;
                            font-size: 1em;
                            margin: 0;
                        }
                    }
                }
            }

            .inforCentro>h2 {
                margin: 0;
                font-weight: bold;
                color: #A8A8A8;
                font-family: Sora;
                font-size: 1.3em;
                margin-bottom: 10px;
            }
        }

        .inforEndereco {
            width: 100%;
            padding: 0.5%;

            .boxInfor {
                display: flex;
                background-color: white;
                align-items: center;
                width: 100%;
                padding: 8px;
                border-radius: 10px;
                height: 65px;
                justify-content: space-between;

                .d-flex {
                    width: 80%;
                    align-items: center;
                    gap: 8px;

                    .infor {
                        font-family: Sora;
                        font-weight: 400;
                        font-size: 1.3vw;
                        margin: 0;
                    }
                }
            }
        }

        .inforEndereco>h2 {
            margin: 0;
            font-weight: bold;
            color: #A8A8A8;
            font-family: Sora;
            font-size: 1.3em;
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 1150px) {
    .containerInfor {
        .subcontainer {
            .agroupboxInfor {
                .inforCentro {
                    .boxInfor {
                        .d-flex {
                            .infor {
                                font-size: 1.1vw;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 950px) {
    .containerInfor {
        .subcontainer {
            .agroupboxInfor {
                .inforCentro {
                    .boxInfor {
                        .d-flex {
                            .infor {
                                font-size: 1.6vw;
                            }
                        }
                    }
                }
            }

            .inforEndereco {
                .boxInfor {
                    .d-flex {
                        .infor {
                            font-size: 2.7vw;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 850px) {
    .containerInfor {
        .subcontainer {
            display: flex;
            flex-direction: column;

            .agroupboxInfor {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                gap: 10px;
                align-items: center;
                justify-content: space-between;

                .inforCentro {
                    min-width: 100%;
                    padding: 0.5%;

                    .boxInfor {
                        display: flex;
                        background-color: white;
                        align-items: center;
                        width: 100%;
                        padding: 8px;
                        border-radius: 10px;
                        height: 65px;
                        justify-content: space-between;

                        .d-flex {
                            gap: 8px;
                            align-items: center;

                            .infor {
                                font-family: Sora;
                                font-weight: 400;
                                font-size: 1.1em;
                                margin: 0;
                            }
                        }
                    }
                }

                .inforCentro>h2 {
                    margin: 0;
                    font-weight: bold;
                    color: #A8A8A8;
                    font-family: Sora;
                    font-size: 1.3em;
                    margin-bottom: 10px;
                }
            }

            .inforEndereco {
                width: 100%;
                margin-top: 15px;

                .boxInfor {
                    display: flex;
                    background-color: white;
                    align-items: center;
                    width: 100%;
                    padding: 8px;
                    border-radius: 10px;
                    height: 65px;
                    justify-content: space-between;

                    .d-flex {
                        width: 80%;
                        align-items: center;
                        gap: 8px;

                        .infor {
                            font-family: Sora;
                            font-weight: 400;
                            font-size: 2.5vw;
                            margin: 0;
                        }
                    }
                }
            }

            .inforEndereco>h2 {
                margin: 0;
                font-weight: bold;
                color: #A8A8A8;
                font-family: Sora;
                font-size: 1.3em;
                margin-bottom: 10px;
            }
        }
    }

}

@media (max-width: 500px) {
    .containerInfor {
        .subcontainer {

            .agroupboxInfor {
                .inforCentro {
                    .boxInfor {
                        .d-flex {
                            .infor {
                                font-family: Sora;
                                font-weight: 400;
                                font-size: 3.5vw;
                                margin: 0;
                            }
                        }
                    }
                }

                .inforCentro>h2 {
                    font-size: 1.3em;
                }
            }

            .inforEndereco {
                .boxInfor {
                    .d-flex {
                        .infor {
                            font-size: 3.3vw;
                        }
                    }
                }
            }

            .inforEndereco>h2 {
                font-size: 1.3em;
            }
        }
    }

}