@import url("../../assets/Fonts/Fonts.css");

.containerDash {
    margin: 0;
    padding: 0;
    background-color: #f4f4f5;
}

.grid-containerr {
    .mensagemm {
        margin-top: 70px;
        height: 120px;
        width: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: black;
        background-size: cover;
        background-image: url("./container.png");

        .d-flex {
            padding: 20px;
            gap: 8px;
            flex-direction: column;
            font-family: Sora;
            color: #fff;
        }

        .d-flex>h2 {
            margin: 0;
            font-weight: bold;
        }

        .d-flex>h3 {
            margin: 0;
            text-align: justify;
            font-weight: 600;
            font-size: 1.4em;
        }
    }

    .CotainerTwo {
        margin-top: 20px;

        .agroupItens {
            max-width: 1500px;
            margin: 0 auto;

            .BoxDashLeft {
                width: 60%;
                display: flex;
                height: auto;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                .items {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    margin: 0;
                    margin-bottom: 40px;
                    margin-top: 40px;

                    .d-flex {
                        justify-content: center;
                        width: 95%;
                        gap: 25px;
                        margin: 0 auto;

                        .Clientes {
                            width: 40%;
                            border-radius: 20px;
                            padding: 20px;
                            background-color: white;
                            height: 350px;

                            .d-flex {
                                align-items: center;
                                justify-content: space-between;
                            }

                            .d-flex>h3 {
                                margin: 0;
                                font-family: Sora;
                                font-weight: bold;
                                color: black;
                            }

                            .boxClien {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                            }

                            .boxClien>button {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 90%;
                                height: 40px;
                                font-weight: bold;
                                font-family: Sora;
                                color: white;
                                font-size: 18px;
                                border-radius: 10px;
                                background-color: #0acc0a;
                                border: none;
                                transition: 0.5s all;

                                &:hover {
                                    transform: scale(1.05);
                                }
                            }
                        }

                        .buttons {
                            display: flex;
                            width: 60%;
                            gap: 30px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;

                            .cVendas {
                                padding: 20px;
                                display: flex;
                                grid-gap: 0;
                                width: 33vw;
                                max-width: 500px;
                                gap: 30px;
                                height: 160px;
                                background-image: url("./img/Rectangle\ 537\ \(1\)\ \(2\).png");
                                text-align: left;
                                border: none;
                                justify-content: center;
                                align-items: center;
                                color: #fff;
                                background-color: #fff;

                                &.btn-venda-avulsa {
                                    background-image: none;
                                    background-color: #5c049c;
                                }

                                border-radius: 25px;
                                cursor: pointer;
                                transition: transform 100ms ease-in-out;
                                text-decoration: none;

                                &:disabled {
                                    cursor: not-allowed;
                                    opacity: 0.6;

                                    &:hover {
                                        transform: initial;

                                        h3 {
                                            text-decoration: none;
                                        }
                                    }
                                }

                                #unable {
                                    position: absolute;
                                    margin: 0;
                                    font-family: Sora;
                                }

                                h3 {
                                    width: 200px;
                                    margin-top: 10px;
                                    font-size: 20px;
                                    word-spacing: 5px;
                                    font-weight: bold;
                                    font-family: Sora;
                                }

                                .d-flex {
                                    flex-direction: column;
                                    width: 280px;

                                    .box {
                                        padding: 10px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 50px;
                                        width: 50px;
                                        border-radius: 10px;
                                        background-color: white;
                                    }
                                }
                            }

                            .cVendas:hover {
                                transform: scale(1.05);

                                h3 {
                                    text-decoration: underline;
                                }
                            }
                        }

                        .comissao {
                            padding: 20px;
                            gap: 30px !important;
                            width: 33vw;
                            max-width: 500px;
                            height: 160px;
                            background-image: url("./img/Rectangle\ 537\ \(1\)\ \(2\).png");
                            text-align: left;
                            border: none;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                            background-color: #fff;

                            border-radius: 25px;
                            cursor: pointer;
                            transition: transform 100ms ease-in-out;
                            text-decoration: none;

                            .icon_valueInLine {
                                display: flex;
                                gap: 30px;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;

                                .d-flex {
                                    flex-direction: column;
                                    width: 280px;

                                    .box {
                                        padding: 10px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 50px;
                                        width: 50px;
                                        border-radius: 10px;
                                        background-color: white;
                                    }
                                }
                            }

                            h4 {
                                font-family: "Montserrat-Medium";
                            }

                            h3 {
                                margin: 0;
                                width: 200px;
                                font-size: 20px;
                                font-weight: bold;
                                font-family: Sora;
                            }

                            .btn-success {
                                font-family: "Montserrat-Medium";
                            }
                        }

                        .comissao:hover {
                            transform: scale(1.05);

                            h3 {
                                text-decoration: underline;
                            }
                        }
                    }

                    .rodape {
                        display: block;
                        margin: 0;
                        bottom: 0;
                        z-index: 200;
                    }
                }

                .ContainerBottomLeft {
                    width: 100%;
                    justify-content: center;
                    margin: 0;
                    margin-top: 40px;

                    .boxsGroup {
                        flex-direction: column;
                        justify-content: center;
                        width: 95%;
                        gap: 25px;
                        margin: 0 auto;

                        .BoxOne {
                            background-color: red;
                            padding: 40px;
                            height: 100px;
                            width: 60%;
                            background-image: url("./img/fundoBoxs.svg");
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .grid-containerr {
        max-width: 100%;
        height: auto;
        background-color: #f4f4f5;

        .CotainerTwo {
            .agroupItens {
                flex-direction: column;

                .BoxDashLeft {
                    width: 100%;
                    margin-bottom: 20px;

                    .items {
                        .d-flex {
                            .buttons {
                                .cVendas {
                                    border-radius: 30px;
                                    min-width: 400px;
                                }

                                .comissao {
                                    border-radius: 30px;
                                    min-width: 400px;
                                }
                            }

                            .Clientes {
                                border-radius: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .menu {
        display: block;
    }

    .grid-containerr {
        display: flex;
        flex-direction: column;

        .items {
            display: flex;
            flex-direction: column;
            height: auto;

            .comissao_sair {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .grid-containerr {
        max-width: 100%;
        background-color: #f4f4f5;

        .mensagemm {
            height: 150px;
        }

        .items {
            .align-items-center {
                flex-direction: column;
            }
        }

        .CotainerTwo {
            .agroupItens {
                flex-direction: column;

                .BoxDashLeft {
                    width: 100%;
                    height: auto;
                    margin-bottom: 30px;

                    .items {
                        .d-flex {
                            .buttons {
                                display: flex;
                                width: 100%;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                .cVendas {
                                    min-width: 75%;
                                }

                                .comissao {
                                    min-width: 75%;
                                }
                            }

                            .Clientes {
                                width: 75%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 580px) {
    .grid-containerr {
        .mensagemm {
            .d-flex>h3 {
                font-size: 1.2em;
            }
        }

        .CotainerTwo {
            .agroupItens {
                flex-direction: column;

                .BoxDashLeft {
                    .items {
                        .d-flex {
                            .buttons {
                                .cVendas {
                                    min-width: 90%;
                                }

                                .cVendas>img {
                                    width: 70px !important;
                                }

                                .comissao {
                                    min-width: 90%;

                                    .icon_valueInLine>img {
                                        width: 70px !important;
                                    }
                                }
                            }

                            .Clientes {
                                width: 90%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .grid-containerr {
        .mensagemm {
            .d-flex>h3 {
                font-size: 1em;
            }
        }

        .CotainerTwo {
            .agroupItens {
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .grid-containerr {
        .CotainerTwo {
            .agroupItens {
                .BoxDashLeft {
                    .items {
                        .d-flex {
                            .buttons {
                                .d-flex {
                                    align-items: center;
                                    text-align: center;
                                }

                                .cVendas>img {
                                    display: none;
                                }

                                .comissao {
                                    .icon_valueInLine {
                                        .d-flex {
                                            align-items: center;
                                            text-align: center;
                                        }
                                    }

                                    .icon_valueInLine>img {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}