@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

.cash-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    background-color: #F8F8F8;
    flex-direction: column;

    .cash-section {
        display: flex;
        position: relative;
        width: 95%;
        gap: 50px;
        max-width: 1400px;
        margin-bottom: 30px !important;
        margin: 0 auto;

        .cash-sectionLeft {
            display: flex;
            flex-direction: column;
            padding: 20px;
            border-radius: 20px;
            box-shadow: 0px 4px 15px rgba(0, 0, 6, 0.15);
            align-items: center;
            justify-content: space-between;
            background-color: #FFF;
            width: 32%;
            min-height: 100%;

            article {

                h2 {
                    margin-top: 40px;
                    font-size: 2.5em;
                    line-height: 55px;
                    font-weight: 800;

                    span {
                        border-bottom: 2.5px solid #0ACC0A;
                    }
                }

                p {
                    font-weight: 500;
                    font-size: 1.3em;
                }
            }

            img {
                position: absolute;
                bottom: 0;
                width: 300px;
            }
        }

        .cash-sectionRigth {
            width: 68%;
            display: flex;
            flex-direction: column;
            gap: 50px;

            .cash-groupboxCash {
                display: flex;
                justify-content: center;
                gap: 50px;

                a {
                    text-decoration: none;
                }

                .cash-boxNotas {
                    background-image: url('./assets/backgroundCash.png');
                    background-size: cover;
                    width: 370px;
                    cursor: pointer;
                    height: 335px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 20px;
                    border-radius: 20px;
                    transition: .6s all;

                    &:hover {
                        transform: scale(1.03);
                    }

                    .cash-headerBox {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;

                            span {
                                font-weight: 700;
                                line-height: 1em;
                                font-size: 1.7em;
                                color: #FFF;
                            }
                        }

                        p {
                            margin-top: 40px;
                            font-size: 1.3em;
                            color: #FFF;
                        }
                    }

                    button {
                        align-self: flex-end;
                        width: 55px;
                        border: none;
                        height: 55px;
                        border-radius: 15px;
                        color: black;

                        img {
                            height: 22px;
                            width: 22px;
                        }
                    }
                }
            }

            .cash-sectionSuport {
                border-top: 20px solid #0ACC0A;
                border-radius: 20px;
                padding: 50px;
                width: 790px;
                margin: 0 auto;

                h2 {
                    font-weight: 700;
                    max-width: 220px;
                    font-size: 1.3em;
                }

                .cash-groupTextSupport {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 2px solid #000;

                    h3 {
                        font-size: 4em;
                    }

                    img {
                        cursor: pointer;
                        height: 50px;
                        transition: .7s all;

                        &:hover {
                            transform: scale(1.07);
                        }
                    }
                }

                p {
                    margin-top: 20px;
                    width: 600px;
                    font-weight: 500;
                }

                button {
                    font-weight: 700;
                    padding: 10px 30px;
                    color: #000;
                    background-color: #00EE00;
                    border: 5px solid #00EE00;
                    border-radius: 20px;
                    font-size: 1.2em;
                    transition: .6s all;

                    &:hover {
                        color: #00EE00;
                        background-color: #FFF;
                    }
                }
            }
        }
    }

    #textCollapse {
        max-width: 1440px;
        width: 80%;
        margin: 0 auto;
        color: #000;
        padding: 0px 20px;
        font-size: 2.5em;
        font-weight: 700;
        margin: 0 auto;
    }

    .collapseGroup {
        display: flex;
        width: 80%;
        gap: 20px;
        align-items: center;
        flex-direction: column;
        max-width: 1400px;
        margin: 20px auto;

        .collapseElement {
            padding: 20px;
            width: 100%;
            border: 10px solid #00EE00;
            border-radius: 15px;

            .headCollapse {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                cursor: pointer;
                align-items: center;
                transition: .6s all ease;

                &:hover {
                    opacity: .6;
                }

                img {
                    transform: rotate(90deg);
                    width: 15px;
                    transition: .8s all;
                }

                h2 {
                    width: 80%;
                    font-size: 1.4em;
                    color: #000;
                    margin: 0;
                    font-weight: 700;
                }
            }

            .inforElement {
                overflow-y: hidden;

                p {
                    height: 0;
                    font-size: 1.2em;
                    transition: .8s;
                    overflow-y: auto;
                    margin: 0;
                    color: #8f8a8a;
                    text-align: justify;
                    font-weight: 700;

                    ul {
                        margin-top: 15px;

                        li {
                            font-size: 1.1em;
                            color: #000;
                            text-align: justify;
                            font-weight: 600;

                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1230px) {
    .cash-container {
        .cash-section {
            .cash-sectionLeft {
                img {
                    width: 23vw;
                }
            }

            .cash-sectionRigth {
                width: 68%;
                display: flex;
                flex-direction: column;
                gap: 50px;

                .cash-groupboxCash {
                    gap: 50px;

                    .cash-boxNotas {
                        max-width: 370px;
                        height: 335px;
                    }
                }

                .cash-sectionSuport {
                    width: 65vw;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .cash-container {
        .cash-section {
            flex-direction: column;

            .cash-sectionLeft {
                position: relative;
                width: 80%;
                margin: 0 auto;
                height: 250px;
                margin-top: 80px;
                padding: 30px;
                justify-content: center;
                align-items: flex-start;

                article {
                    width: 60%;
                    justify-content: center;

                    h2 {
                        margin-top: 0;
                    }
                }

                img {
                    height: 120%;
                    right: 10px;
                    width: auto;
                }
            }

            .cash-sectionRigth {
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: 50px;
                margin: 0 auto;

                .cash-groupboxCash {
                    gap: 30px;
                    justify-content: space-between;

                    .cash-boxNotas {
                        width: 400px;
                        height: 335px;
                    }
                }

                .cash-sectionSuport {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 1020px) {
    .cash-container {
        .cash-section {
            .cash-sectionLeft {
                width: 100%;
            }

            .cash-sectionRigth {
                width: 100%;
            }
        }

        .collapseGroup {
            width: 95%;
        }

        #textCollapse {
            width: 95%;
        }
    }
}

@media (max-width: 810px) {
    .cash-container {
        .cash-section {

            .cash-sectionRigth {
                .cash-groupboxCash {
                    .cash-boxNotas {
                        width: 330px;
                    }
                }
            }
        }
    }
}

@media (max-width: 750px) {
    .cash-container {
        .cash-section {
            .cash-sectionLeft {
                margin-top: 20px;

                article {
                    width: 60%;
                    justify-content: center;

                    h2 {
                        margin-top: 0;
                        font-size: 2em
                    }

                    p {
                        font-size: 1.1em;
                    }
                }

                img {
                    height: 105%;
                    right: 10px;
                    width: auto;
                }
            }

            .cash-sectionRigth {
                width: 100%;

                .cash-groupboxCash {
                    gap: 30px;
                    justify-content: space-between;

                    .cash-boxNotas {
                        width: 300px;
                        height: 355px;
                    }
                }

                .cash-sectionSuport {
                    width: 100%;
                    padding: 30px;

                    .cash-groupTextSupport {
                        h3 {
                            font-size: 3.5em;
                        }
                    }

                    p {
                        width: auto;
                    }
                }
            }
        }
    }
}

@media (max-width: 650px) {
    .cash-container {
        .cash-section {
            .cash-sectionLeft {

                article {
                    width: 60%;
                    justify-content: center;

                    h2 {
                        margin-top: 0;
                        font-size: 2em
                    }

                    p {
                        font-size: 1.1em;
                    }
                }

                img {
                    height: 105%;
                    right: 10px;
                }
            }

            .cash-sectionRigth {
                width: 100%;

                .cash-groupboxCash {
                    gap: 20px;
                    flex-direction: column;

                    .cash-boxNotas {
                        width: 100%;
                        max-width: 100%;
                        height: 290px;
                    }
                }

                .cash-sectionSuport {
                    width: 100%;
                    padding: 30px;

                    .cash-groupTextSupport {
                        h3 {
                            font-size: 3em;
                        }
                    }
                }
            }
        }

        #textCollapse {
            font-size: 1.8em;
            padding: 0 10px;
        }

        .collapseGroup {
            width: 95%;

            .collapseElement {

                .headCollapse {

                    img {
                        width: 13px;
                    }

                    h2 {
                        font-size: 1.2em;
                    }
                }

                .inforElement {

                    p {
                        font-size: 1em;

                        ul {
                            margin-top: 15px;

                            li {
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }

        #textCollapse {
            width: 95%;
        }
    }
}

@media (max-width: 610px) {
    .cash-container {
        .cash-section {
            .cash-sectionLeft {
                height: 500px;
                justify-content: start;
                align-items: center;

                article {
                    width: 100%;

                    h2 {
                        font-size: 2.5em;
                    }

                    p {
                        font-size: 1.5em;
                    }
                }

                img {
                    height: 65%;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
                }
            }

            .cash-sectionRigth {
                width: 100%;

                .cash-groupboxCash {
                    gap: 20px;
                    flex-direction: column;

                    .cash-boxNotas {
                        width: 100%;
                        height: 290px;
                    }
                }

                .cash-sectionSuport {
                    width: 100%;
                    padding: 30px;

                    .cash-groupTextSupport {
                        h3 {
                            font-size: 3em;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .cash-container {
        .cash-section {
            .cash-sectionLeft {
                height: auto;
                justify-content: start;
                align-items: center;

                article {
                    h2 {
                        font-size: 1.8em;
                    }

                    p {
                        font-size: 1.2em;
                        text-align: justify;
                    }
                }

                img {
                    display: none;
                }
            }

            .cash-sectionRigth {
                .cash-groupboxCash {
                    .cash-boxNotas {
                        height: 350px;
                    }
                }

                .cash-sectionSuport {
                    h2 {
                        font-size: 1.2em;
                        max-width: auto;
                    }

                    .cash-groupTextSupport {
                        h3 {
                            font-size: 2em;
                            margin-bottom: 10px;
                        }

                        img {

                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .cash-container {
        .collapseGroup {
            width: 98%;

            .collapseElement {

                .headCollapse {
                    img {
                        width: 10px;
                    }

                    h2 {
                        width: 85%;
                        font-size: 1em;
                    }
                }

                .inforElement {

                    p {
                        font-size: .95em;

                        ul {
                            margin-top: 15px;

                            li {
                                font-size: .95em;
                            }
                        }
                    }
                }
            }
        }
    }
}